import {getLinkData, getParams, getUrl, setLinkData} from "../../../../redux/lots/action";
import {removeFilter} from "../../../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import React from "react";
import {useDispatch} from "react-redux";
import {Link} from "react-router-dom";
import * as queryString from "query-string";
import Helpers from "../../../../services/helpers";
import {includes} from "lodash/collection";

const CategoryLinkComponent = (props) => {

    const {item, className = ''} = props;

    const {category_description, auction_category_id} = item;

    const dispatch = useDispatch();

    let linkParams = dispatch(getParams());

    const {user_lots_type} = linkParams

    if (includes(['auction_lots', 'archive_lots'], user_lots_type)) {

        const {link, params, obj} = dispatch(getUrl())

        const getLinkParams = () => {

            delete linkParams.auction_category_id;

            linkParams.page = 1;
            const urlParams = dispatch(getLinkData(linkParams));

            const userSortParams = (user_lots_type === 'auction_lots')
                ? Helpers.getUserAuctionSorting() : (user_lots_type === 'archive_lots') ? params : obj;

            if (userSortParams.direction === urlParams.direction) {
                delete urlParams.direction;
            }

            if (userSortParams.order === urlParams.order) {
                delete urlParams.order;
            }

            return queryString.stringify(urlParams);
        }

        const l = (user_lots_type === 'auction_lots') ? `/auctions/` : link

        let paramsTo  = {
            pathname: `${l}${Helpers.getCategoryVariableReference(item)}`,
        }

        const setParamsTo = () => {
            paramsTo = {
                pathname: `${l}${Helpers.getCategoryVariableReference(item)}`,
                search: `?${getLinkParams()}`
            }
        }

        const setParameters = () => {
            if(linkParams.prefix_id !== undefined) {
                dispatch(removeFilter('prefix_id'))
                dispatch(setLinkData({page: 1}));
                linkParams = dispatch(getParams());
            }
            setParamsTo();
        }

        const getParamsTo = () => {
            return paramsTo
        }

        return (
            <Link onClick={setParameters} className={className} to={getParamsTo}>
                {props.children}
            </Link>
        )
    }


    return (

        <a href={`/auctions/${Helpers.getCategoryVariableReference(item)}`}
           onClick={(e) => {
               e.preventDefault()
               dispatch(setLinkData({auction_category_id, page: 1}))
           }}
           title={category_description}>
            {props.children}
        </a>

    )


}
export default CategoryLinkComponent