import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {filter, find} from "lodash/collection";
import {isEmpty} from "lodash/lang";
import {removeFilter, resetFilter} from "../../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import {getQueryLinkParams, setLinkData} from "../../../redux/lots/action";

const FilterTopBar = () => {
    const dispatch = useDispatch();
    const {auction_category_id} = dispatch(getQueryLinkParams());

    const {
        lots: {
            phrases
        },
        filterSidebar: {filterInput, blockData, loading},
    } = useSelector((state) => {
        return state
    })

    const {
        ak_au_reset_filter,
        auctions_interesting_lots,
        option_filter_category_values,
        ak_au_phrase_promotion
    } = phrases




    const filters = filter(filterInput, {'initial': false})

    const getDataFilter = (val) => {
        const {name, value} = val;
        let data = {}

        const getBlockCategory = () => {

            if (blockData === undefined) {
                return [];
            }

            return find(blockData, {block: name});
        }

        switch (name) {
            case'promotion':
                return {phrase: ak_au_phrase_promotion}
            case'interesting':
                return {phrase: auctions_interesting_lots}
            case'prefix_id':
                return {phrase: phrases[`category_prefix_${value}`]}
            case'filter_category':

            case'filter_new_lots':
            case'filter_start_price':
            case'filter_current_price':
            case'filter_delivery':
            case'filter_sale_type':
            case'filter_seller_rating':
            case'filter_timeout':
            case'filter_subcategories':
            case'filter_lot_type':
            default:
                const defaultFn = () => {
                    const {phrase, block_category} = getBlockCategory()

                    let returnData = {};

                    block_category.map(({filterData, phrase}) => {
                        if (filterData.value == value) {
                            returnData = {phrase}
                        }
                    });

                    if (isEmpty(returnData)) {
                        return {phrase: phrase}
                    }

                    return returnData
                }

                return defaultFn();


        }
    }

    const clearFilter = (data) => {
        dispatch(removeFilter(data))
        dispatch(setLinkData({page: 1}));
    }

    if (filters.length > 0) {

        const getFilterButtons = () => {

            if (loading) {
                return null;
            }

            if (filters.length > 0) {

                return filters.map((val, key) => {

                    if (auction_category_id !== 0 && val.name === 'filter_category') {

                        return null
                    }

                    const {phrase} = getDataFilter(val)

                    return (
                        <button key={key} type="button" onClick={() => clearFilter(val.name)}
                                className="btn btn-success btn-sm btn-rg m-1">
                            {phrase} <span aria-hidden="true">&times;</span>
                        </button>);
                })
            }

            return null
        }

        const resetButton = (filters.length > 0) ?
            <button type="button" onClick={() => dispatch(resetFilter())}
                    className="btn btn-success btn-sm btn-rr m-1">
                {ak_au_reset_filter}
            </button>
            : null;


        return (
            <div className="row mb-1">
                <div className="col-12">
                    <div className="section-main">
                        <div className="m-1 d-flex flex-wrap">
                            {getFilterButtons()}
                            {resetButton}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return null;

}
export default FilterTopBar