import React, {useContext} from 'react'
import {isNil} from "lodash/lang";
import ModalPrefixErrorBlock from "./modal-prefix-error-block";
import ModalPrefixOptionsBlock from "./modal-prefix-options-block";
import {ModalPrefixContext} from "./modal-prefix"

const ModalPrefixContent = () => {

    const {
        error,
        auctionIds,
        selectedPrefix,
        setChosenPrefix,
        phrases:{ak_au_you_sure_you_want_to_prefix_x_lots, prefix}
    } = useContext(ModalPrefixContext)

    if (!isNil(error)) {
        return <ModalPrefixErrorBlock error={error}/>
    }

    return (
        <>
            <div className='row justify-content-center mb-2 flex-wrap'>
                <div className="col-8 px-1">
                    {ak_au_you_sure_you_want_to_prefix_x_lots
                        .replace(/\{count\}/, auctionIds.length)}
                </div>
            </div>
            <div className="form-group row justify-content-center">
                <label className="col-sm-2 col-form-label text-right">{prefix}:</label>
                <div className="col-sm-6">
                    <select defaultValue={selectedPrefix} className="form-control"
                            onChange={(e) => {
                                const prefix = parseInt(e.target.value)
                                setChosenPrefix(prefix);
                            }}>
                        <ModalPrefixOptionsBlock/>
                    </select>
                </div>
            </div>
        </>
    )
}

export default ModalPrefixContent