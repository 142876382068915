import React, {useContext, useMemo} from 'react'
import Helpers from "../../../services/helpers";
import {useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";
import {includes} from "lodash/collection";

const BuyerOrSallerBlock = (props) => {
    const {lotData} = useContext(lotContext);
    const {status, user_id: auction_user_id, top_bidder} = lotData
    const {word, name, user_id, rating} = props;

    const {
        visitor: {
            user_id: visitor_user_id,
            permissions: {
                auctions: {
                    RestoreLots,
                    view_all_bids,
                    deleteLots
                }
            }
        },
        phrases: {ak_au_ph_rating},

    } = useSelector(state => state.lots);

    return useMemo(() => {

        if (includes(['active', 'vacation'], status)) {
            return null
        }

        if (includes([auction_user_id, top_bidder], visitor_user_id) || deleteLots || RestoreLots || view_all_bids) {
            return (
                <div className='d-flex mt-1 user'>
                    <span className='mr-1 text-muted'>{word}:</span>
                    <div className="d-flex flex-column align-items-center">
                        <span className='user_name'>{Helpers.getUserLink({username: name, user_id})}</span>
                    </div>
                </div>
            )
        }

        return null;

    }, [auction_user_id, top_bidder, visitor_user_id, deleteLots, RestoreLots, view_all_bids, status])
}

export default BuyerOrSallerBlock