import helpers from "./helpers";
import * as queryString from "query-string";
import {isEmpty} from "lodash/lang";
import {encode} from "base-64";


export default class BaseApiService {
    _baseUrl = ''
    _apiBase = ''
    _defaultQuery = {}

    constructor() {
        this._baseUrl = helpers.getBaseUrl()
        this._defaultQuery = helpers.getDefaultQueryParams();
        this._apiBase = '';
    }

    getResource = async (url, obj = {}, method = 'GET') => {

        const query = this.getParams(obj)

        let queryParams = {}
        let headers = helpers.getHeaders()

        if (query instanceof FormData) {
            queryParams = query;
            headers = {}

            if (window.location.hostname === 'localhost') {
                const username = 'dev'
                const password = 'dev112014'
                headers = {...headers, 'Authorization': `Basic ${encode(`${username}:${password}`)}`}
            }

        } else {
            queryParams = !isEmpty(query)
                ? (method === 'GET') ? `/?${query}` : query
                : (method === 'GET') ? '' : {}
        }

        const link = `${this._apiBase}${url}`
        const fullUrl = (method === 'GET') ? `${link}${queryParams}` : link

        let params = {
            method,
            credentials: 'same-origin',
            headers
        }

        if (method !== 'GET') {
            params.body = queryParams
        }

        const response = await fetch(fullUrl, params);

        const ajaxData = await response.json();

        this._protectedRunXenForoMethod(ajaxData)

        if ('error' in ajaxData || !response.ok) {

            console.error(`Could not fetch ${url}, received ${response.status}`)

            // throw new Error(`Could not fetch ${url}, received ${response.status}`)
        }

        return ajaxData;
    }

    getParams = (query = {}) => {

        if (query instanceof FormData) {
            const {_xfNoRedirect, _xfResponseType, _xfRequestUri} = this._defaultQuery

            query.append('_xfNoRedirect', _xfNoRedirect);
            query.append('_xfResponseType', _xfResponseType);
            query.append('_xfRequestUri', _xfRequestUri);

            if ('_xfToken' in this._defaultQuery) {
                query.append('_xfToken', this._defaultQuery._xfToken);
            }

            return query
        }

        let data = {...query, ...this._defaultQuery}

        return queryString.stringify(data);
    }

    _protectedRunXenForoMethod = (ajaxData) => {
        if (typeof ajaxData == 'object') {
            if (window.XenForo !== undefined) {
                window.XenForo.protectedRunXenForoMethod(ajaxData)
            }
        }
    }

}