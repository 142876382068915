import React from "react";
import SortingSettingsContentSelect from "./sorting-settings-content-select";

const SortingSettingsContent = (props) => {
    const {order, direction, setOrder, setDirection, directionData = {}, sortingData = {}} = props

    const getOptionOrder = () => {
        return sortingData.map((data) => {
            const {orderSelectTitle, order} = data
            return {name: orderSelectTitle, value: order}
        });
    }

    const getOptionDirection = () => {
        return Object.keys(directionData).map((key) => {
            return {name: directionData[key], value: key}
        });
    }


    return (
        <div className="container">
            <div className="row flex-wrap">
                <SortingSettingsContentSelect
                    setAction={setOrder}
                    value={order}
                    name="order"
                    dataOptions={getOptionOrder()}
                />
                <SortingSettingsContentSelect
                    setAction={setDirection}
                    value={direction}
                    name="direction"
                    dataOptions={getOptionDirection()}
                />
            </div>
        </div>
    )
}
export default SortingSettingsContent