import React, {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getQueryLinkParams} from "../../../redux/lots/action";
import {find} from "lodash/collection";
import {Icon} from "@iconify/react";
import './input-show-img-list.css'

const InputShowImgList = (props) => {

    const dispatch = useDispatch()

    const {setShow, showImgLotsList} = props

    const {user_sort_data, phrases: {ak_au_turn_off_images = 'Turn off', ak_au_turn_on_images = 'Turn on'}}
        = useSelector(state => {
        return state.lots
    });

    const {user_lots_type, type} = useMemo(() => {
        return dispatch(getQueryLinkParams());
    }, [user_sort_data]);

    const {show_img_lots_list} = find(user_sort_data, {user_lots_type, type});

    useEffect(() => {
        setShow(show_img_lots_list);
    }, [])


    return (
        <div className="col-12">
            <div className="row justify-content-center py-2">

                <div className='btn btn-sm btn-rg text-white'>
                    <span className='mx-1 mb-1'>
                        {ak_au_turn_off_images}
                    </span>
                    <span className='btn btn-sm'>
                        <span className='mx-2' onClick={() => setShow(!showImgLotsList)}>
                        {showImgLotsList ? <Icon icon="bi:toggle2-off"/> : <Icon icon="bi:toggle2-on"/>}
                            </span>
                    </span>

                </div>
            </div>
        </div>
    )

}

export default InputShowImgList