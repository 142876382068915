import React, {useContext} from 'react'
import {map} from "lodash/collection";
import ModalPrefixOptionItem from "./modal-prefix-option-item";
import {ModalPrefixContext} from "./modal-prefix";

const ModalPrefixOptionsBlock = () => {
    const {
        phrases,
        prefixes
    } = useContext(ModalPrefixContext)

    return map(prefixes, (value, key) => {
        if (value.title) {
            return (
                <optgroup key={key} label={phrases[`category_prefix_group_${key}`]}>
                    <ModalPrefixOptionItem {...value}/>
                </optgroup>
            )
        }

        return <ModalPrefixOptionItem {...value}/>
    })

}
export default ModalPrefixOptionsBlock