import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {setFilter} from "../../../redux/sidebars/filter-sidebar/actionFilterSideBar";
import {getInterestingLots} from "../../../redux/top-bar/interesting-lots/actionInterestingLots";
import AdvertisingBlock from "../../repeating-components/advertising-block/advertising-block";
import {useMediaQuery} from "react-responsive";

const InterestingLots = () => {

    const dispatch = useDispatch();

    const {
        lots: {
            phrases: {
                auctions_interesting_lots = ''
            },
        },
        interestingLotsReducer: {interestingLots = {}}
    } = useSelector((state) => {
        return state
    })

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});

    React.useEffect(() => {
        if (!isTabletOrMobile) {
            dispatch(getInterestingLots());
        }
    }, [isTabletOrMobile])

    const setFilterInterestingLot = () => {
        dispatch(setFilter({name: 'interesting', value: 1}))
    }

    return useMemo(() => {

        return (isTabletOrMobile) ? null : (
            <AdvertisingBlock
                lots={interestingLots}
                lotFilter={setFilterInterestingLot}
                blockTitle={auctions_interesting_lots}
                viewAllLink={'/?interesting=1'}
            />
        )

    }, [isTabletOrMobile, interestingLots])


}

export default InterestingLots