import {find} from "lodash/collection";
import Helpers from "../../services/helpers";
import {getAllParentsByCategoryId, getQueryLinkParams, getUrl, setLinkData} from "../lots/action";
import {push} from "connected-react-router";
import {isNaN} from "lodash/lang";

export function getArchiveSwichData(y = NaN) {

    return (dispatch, getState) => {
        const {
            phrases:
                {
                    ak_au_archive_sale,
                    ak_au_archive_sales_category,
                    ak_au_ph_archive_unfinished_lots,
                    ak_au_ph_archive_category_unfinished_lots,
                    ak_au_ph_archive_unsold_lots,
                    ak_au_ph_archive_category_unsold_lots
                }
        } = getState().lots

        const date = new Date();
        const year = (!isNaN(y)) ? y : date.getFullYear();

        return [
            {
                title: ak_au_archive_sale,
                type: 'sale',
                order: 'date_of_sale',
                direction: 'desc',
                title_by_category: ak_au_archive_sales_category,
                link: `/archive/${year}/sold/`,
            },
            {
                title: ak_au_ph_archive_unfinished_lots,
                type: 'unfinished',
                order: 'time',
                direction: 'desc',
                title_by_category: ak_au_ph_archive_category_unfinished_lots,
                link: `/archive/${year}/wait/`,
            },
            {
                title: ak_au_ph_archive_unsold_lots,
                type: 'finalized',
                order: 'time',
                direction: 'desc',
                title_by_category: ak_au_ph_archive_category_unsold_lots,
                link: `/archive/${year}/no-sold/`,
            }
        ];
    }
}

export function setXenArchiveTitle() {

    return (dispatch, getState) => {
        const {auction_category_id, type} = dispatch(getQueryLinkParams());

        const data = dispatch(getArchiveSwichData())
        const {title, title_by_category} = find(data, {type});
        const {auctions} = getState().lots.phrases

        document.title = `${title} | REIBERT.info`;

        let xenTitle = document.querySelector('div.titleBar h1');
        let documentTitle = title;
        let lotsBreadCrumbs = [
            {
                'href': '/',
                'value': auctions,
                action: () => {
                    dispatch(push('/'))
                }
            }
        ];

        let lastElRes = null
        const {link} = dispatch(getUrl());

        if (auction_category_id !== 0) {
            let res = dispatch(getAllParentsByCategoryId(auction_category_id)).reverse();

            let catTitle = '';
            res.map((item, key) => {
                const{category_title,} = item
                let separator = (res.length - 1 === key) ? '' : ' - ';
                catTitle += category_title + separator

                lotsBreadCrumbs.push({
                    'href': `/auctions/${Helpers.getCategoryVariableReference(item)}`,
                    'value': category_title,
                    action: () => {
                        const linkTo = `/auctions/${Helpers.getCategoryVariableReference(item)}`;
                        dispatch(push(linkTo));
                    }
                });
                return true;
            });

            documentTitle = title_by_category.replace(/\{category\}/, catTitle);
            lastElRes = res[res.length - 1];
        }
        let lastLink;

        if (lastElRes !== null){
             lastLink = `${link}${Helpers.getCategoryVariableReference({
                category_title: lastElRes.category_title,
                auction_category_id
            })}`
        }


        lotsBreadCrumbs.push({
            'href': (lastElRes !== null) ? lastLink : link,
            'value': title
        });

        if (xenTitle !== null) {
            xenTitle.innerText = documentTitle;
        }

        const breadcrumsTop = document.querySelector('.breadBoxTop nav fieldset.breadcrumb span.crumbs');
        const breadcrumsBottom = document.querySelector('.breadBoxBottom nav fieldset.breadcrumb span.crumbs');

        if (breadcrumsTop !== null && breadcrumsBottom !== null) {
            Helpers.makeBreadCrumb(breadcrumsTop, lotsBreadCrumbs)
            Helpers.makeBreadCrumb(breadcrumsBottom, lotsBreadCrumbs)
        }
    }
}