import {find} from "lodash/collection";
import {merge} from 'lodash/object'
import {GET_DFAULT_LOTS_LIST_PARAMS, SET_SIDEBAR_BLOCK_DATA, SET_SIDEBAR_LOADING_LOTS_IN_SIDEBAR, SET_SIDEBAR_LOTS_COUNT} from "../../types";
import {getQueryLinkParams, getUrl} from "../../lots/action";
import Helpers from "../../../services/helpers";
import ReibertApiService from "../../../services/reibert-api-service";
import {push} from "connected-react-router";


const reibertApiService = new ReibertApiService();

export function getLotsCountInSidebar() {
    return async (dispatch) => {
       reibertApiService.getCountLotsInSidebar().then(json => {
           const {sidebar_counts} = json;
           dispatch({type: SET_SIDEBAR_LOTS_COUNT, payload: sidebar_counts})
           dispatch(updateParamsUserSidebar(true));
        });
    }
}

export function setCountLotsInSidebar(data) {
    return async (dispatch) => {
        dispatch({type: SET_SIDEBAR_BLOCK_DATA, payload: data})
        dispatch(updateParamsUserSidebar(false));
    }
}


export function updateParamsUserSidebar(type) {
    return dispatch => {
        dispatch({type: SET_SIDEBAR_LOADING_LOTS_IN_SIDEBAR, payload: type})
    }
}

export function setShowBlock(block, type) {
    return (dispatch, getState) => {
        const state = getState();

        const {blockData} = (type === SET_SIDEBAR_BLOCK_DATA) ? state.userSidebar : state.filterSidebar

        const newArr = blockData.map(item => {
            if (item.block === block) {
                item.show = !item.show;
            }
            return item
        });

        dispatch({type: type, payload: newArr})
    }
}

export function actionLink(val) {
    const {user_lots_type, type, order, direction} = val

    const data = {user_lots_type}
    const dataType = {type, order, direction}

    return (dispatch, getState) => {
        const {visitor_user_id, user_id} = getState().lots;
        const {user_lots_type, type} = dispatch(getQueryLinkParams());
        if (user_lots_type !== data.user_lots_type || type !== dataType.type || visitor_user_id !== user_id) {
            const defaultData = {changeLots: [], loading: true, user_id: visitor_user_id}
            dispatch({type: GET_DFAULT_LOTS_LIST_PARAMS, payload: defaultData});
        }
        return true;
    }
}

export function setXenTitle() {

    return (dispatch, getState) => {

        const {phrases} = getState().sidebar

        const {phrases: {auctions}, visitor, user_owner_data} = getState().lots

        const {link} = dispatch(getUrl());

        const {user_id, user_lots_type, type} = dispatch(getQueryLinkParams())
        const dataNav = Helpers.getNavigationData(phrases);

        const data = find(dataNav, {user_lots_type, type});
        const {owner_title = '', title: dataTitle} = data;

        const title = (visitor.user_id !== user_id && user_id !== undefined)
            ? owner_title.replace(/\{name\}/, user_owner_data.username) : dataTitle;

        document.title = `${title} | REIBERT.info`;

        let xenTitle = document.querySelector('div.titleBar h1');
        let documentTitle = title;
        let lotsBreadCrumbs = [
            {
                'href': '/',
                'value': auctions,
                action: () => {
                    dispatch(push('/'))
                }
            }
        ];
        lotsBreadCrumbs.push({
            'href': link,
            'value': title
        });

        if (xenTitle !== null) {
            xenTitle.innerText = documentTitle;
        }

        const breadcrumsTop = document.querySelector('.breadBoxTop nav fieldset.breadcrumb span.crumbs');
        const breadcrumsBottom = document.querySelector('.breadBoxBottom nav fieldset.breadcrumb span.crumbs');

        if (breadcrumsTop !== null && breadcrumsBottom !== null) {
            Helpers.makeBreadCrumb(breadcrumsTop, lotsBreadCrumbs)
            Helpers.makeBreadCrumb(breadcrumsBottom, lotsBreadCrumbs)
        }
    }
}


