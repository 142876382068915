import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {map} from "lodash/collection"
import {toInteger, isEmpty} from "lodash/lang";
import LotFormPrice from "./lot-form-price";
import {setParamsPromotionLots} from "../../../redux/modals/modal-promotion-lots/actionPromotionLots";
import Helpers from "../../../services/helpers";

const PromotionContent = () => {
    const dispatch = useDispatch();

    const {
        promotionLots, modalShow, maxPeriod, total, error,
        promotionData
    } = useSelector(state => state.modalPromotion);

    const {
        ak_au_ph_set_promotion_max_period, BRC_credit, ak_au_ph_promotion_amount,
        ak_au_promotion_error_money, ak_au_replenish_account
    } = useSelector(state => state.lots.phrases);

    const {visitor} = useSelector(state => state.lots);
    const credits = toInteger(visitor.credits)

    useEffect(() => {
        const error = (isEmpty(promotionData) || total > credits);
        dispatch(setParamsPromotionLots({error}))
    }, [promotionData, total])

    const handleMaxPeriod = () => {
        dispatch(setParamsPromotionLots({maxPeriod: !maxPeriod}))
    }

    if (!modalShow) return null

    const getLotsFromPrice = () => {
        return map(promotionLots, (item, key) => {
            return <LotFormPrice key={key} {...item} />
        })
    }

    const errorMessage = () => {

        if (error && total > credits) {
            return (
                <div className="alert alert-danger" role="alert">
                    <span className="text-center">
                        {ak_au_promotion_error_money} <a href="/credits"
                                                         className="alert-link">{ak_au_replenish_account}</a>
                    </span>
                </div>
            )
        }

        return null
    }

    return (
        <form>
            {getLotsFromPrice()}

            <div className="form-check">
                <input className="form-check-input" onChange={() => handleMaxPeriod()}
                       value={maxPeriod}
                       checked={maxPeriod}
                       type="checkbox"/>
                <label className="form-check-label" htmlFor="defaultCheck1">
                    {ak_au_ph_set_promotion_max_period}
                </label>
            </div>


            <div className="form-group row justify-content-lg-center pt-3">
                <div className="col-6 col-sm-4 text-right">{BRC_credit}:</div>
                <div className=" col-6 col-sm-6 align-items-center font-weight-bold">
                    {Helpers.currencyFormat(visitor.credits)}
                </div>
            </div>
            <div className="form-group row justify-content-lg-center">
                <div className="col-6 col-sm-4 text-right">{ak_au_ph_promotion_amount}:</div>
                <div className="col-6 col-sm-6 align-items-center font-weight-bold">
                    {Helpers.currencyFormat(total)}
                </div>
            </div>

            <div className="form-group row justify-content-center">
                <div className="col-12 col-lg-8">
                    {errorMessage()}
                </div>

            </div>
        </form>
    );

}
export default PromotionContent