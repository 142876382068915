import React, {useEffect, useMemo} from "react"
import {useDispatch, useSelector} from "react-redux";
import {isEmpty} from "lodash/lang";
import {filter, includes} from "lodash/collection"

import WithSuspense from "../repeating-components/wrapper-components/with-suspense";
import './sidebar.css';
import {getQueryLinkParams} from "../../redux/lots/action";
import {useMediaQuery} from "react-responsive";
import {setFiltersData} from "../../redux/sidebars/filter-sidebar/actionFilterSideBar";

const NewPopularEndingSidebar = React.lazy(() => import('./sidebar-components/new-popular-ending-sidebar'));
const UserSideBar = React.lazy(() => import('./sidebar-components/user-sidebar/user-sidebar'));
const FilterSideBar = React.lazy(() => import('./sidebar-components/filter-sidebar'));
const CategoriesSidebar = React.lazy(() => import('./sidebar-components/categories-sidebar'));
const SideBarAuctionModerators = React.lazy(() => import('./sidebar-components/sidebar-auction-moderators'));
const SideBarOnlineUser = React.lazy(() => import('./sidebar-components/sidebar-online-users'));
const SideBarStats = React.lazy(() => import('./sidebar-components/sidebar-stats'));
const SideBarArchive = React.lazy(() => import('./sidebar-components/sidebar-archive'));

const Sidebars = () => {

    const dispatch = useDispatch();

    const {
        lots: {simulateLoading, visitor: {user_id: visitor_user_id}},
        sidebar: {loading},
        auctionModeratorsSidebar: {onlineStaff},
        onlineUsersSidebar: {data},
        sidebarStats: {statsData},
        filterSidebar:{blockData}
    } = useSelector((state) => {
        return state
    })

    const {user_lots_type, auction_category_id} = dispatch(getQueryLinkParams())

    const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'});

    const arrData = useMemo(() => {
        const check = (user_lots_type !== 'auction_lots' || auction_category_id !== 0)
        const conditionType = includes(['archive_lots', 'auction_lots'], user_lots_type)

        const arr = [
            {
                component: <CategoriesSidebar/>,
                condition: user_lots_type === 'auction_lots',
                order: 1
            },
            {
                component: <FilterSideBar/>,
                condition:  user_lots_type === 'auction_lots',
                order: 2
            },
            {
                component: <NewPopularEndingSidebar/>,
                condition: visitor_user_id === 0 && !isTabletOrMobile && conditionType,
                order: 3
            },
            {
                component: <SideBarAuctionModerators/>,
                condition: conditionType && onlineStaff.length !== 0,
                order: 4
            },
            {
                component: <UserSideBar/>,
                condition: visitor_user_id !== 0,
                order: 5
            },
            {
                component: <SideBarOnlineUser/>,
                condition: (!check || !isEmpty(data)) && conditionType,
                order: 6
            },
            {
                component: <SideBarStats/>,
                condition: !check || !isEmpty(statsData),
                order: 7
            },
            {
                component: <SideBarArchive/>,
                condition: !check,
                order: 8
            },
        ];

        return filter(arr, (i) => (i.condition))

    }, [user_lots_type, visitor_user_id, statsData, data, onlineStaff, blockData]);


    if (loading || simulateLoading) {
        return null;
    }

    return (
        <React.Fragment>
            <div className="d-flex flex-lg-column flex-md-row section-md-main flex-wrap justify-content-center justify-content-sm-start">
                {arrData.map((i, k) => (
                    <React.Fragment key={k}>
                        <WithSuspense>{i.component}</WithSuspense>
                    </React.Fragment>
                ))}
            </div>
        </React.Fragment>
    );

}
export default Sidebars;