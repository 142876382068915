import React, {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import FilterTopBar from "./filter-top-bar";
import ArchiveFollowRssBlock from "./archive-follow-rss-block";
import InterestingLots from "./interesting-lots";
import CategoriesNav from "./categories-nav";
import {getQueryLinkParams} from "../../redux/lots/action";
import {filter, includes, map} from "lodash/collection";

const TopBar = () => {
    const dispatch = useDispatch();

    const {loadingBaseParams} = useSelector((state) => {
        return state.lots
    })

    const {user_lots_type} = dispatch(getQueryLinkParams());

    return useMemo(() => {

        const arr = [
            {
                condition: user_lots_type === 'auction_lots',
                component: <ArchiveFollowRssBlock/>
            },
            {
                condition: user_lots_type === 'auction_lots',
                component: <InterestingLots/>
            },
            {
                condition: includes(['auction_lots', 'archive_lots'], user_lots_type),
                component: <CategoriesNav/>
            },
            {
                condition: includes(['auction_lots', 'archive_lots'], user_lots_type),
                component: <FilterTopBar/>
            },
        ];

        return map(filter(arr, 'condition'), (obj, key) => {
            return (
                <React.Fragment key={key}>
                    {obj.component}
                </React.Fragment>
            )
        })

    }, [user_lots_type, loadingBaseParams])

}
export default TopBar