import React from 'react';
import './page-nav.css';
import {setLinkData} from "../../redux/lots/action";
import {setParamsPageNav} from "../../redux/page-nav/actionPageNav";
import PaginationBlock from "./pagination-block";
import {useDispatch} from "react-redux";

const PageNav = (props) => {

    const dispatch = useDispatch();
    const setActivePage = (activePage) => {
        dispatch(setLinkData({page: activePage}));
        dispatch(setParamsPageNav({activePage}));
    };

    const setActivePageBottomNav = (activePage) =>{
        setActivePage(activePage);
        window.scrollTo({top: 0, behavior: 'smooth'});    }


    return (
        <>
            <PaginationBlock setActivePage={setActivePage}/>
            {props.children}
            <PaginationBlock setActivePage={setActivePageBottomNav}/>
        </>

    )
}
export default PageNav