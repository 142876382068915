import React, {useCallback, useEffect, useMemo} from 'react'
import {deleteChangeLot, getQueryLinkParams, setChangeLot} from "../../redux/lots/action";
import {toggleModal} from "../../redux/modals/modal-inline-mod-check/actionModal";
import {useDispatch, useSelector} from "react-redux";
import {setDataImageGallery} from "../../redux/modals/modal-image-gallery/actionImageGallery";
import {find, sortBy} from "lodash/collection";

const useLotListFn = () => {

    const dispatch = useDispatch();

    const {lotsData, phrases, all_categories, xenOptions, changeLots, user_sort_data} = useSelector(state => state.lots);
    const {type, user_lots_type} = dispatch(getQueryLinkParams());

    const {promotion_color} = xenOptions;

    useEffect(() => {
        // Activate all registered controls
        window.XenForo !== undefined && window.XenForo.activate(document)
    }, []);

    const onToggleLots = useCallback((auction_id, change) => {
        if (change) {
            dispatch(setChangeLot(auction_id));
            dispatch(toggleModal(change))
        } else {
            dispatch(deleteChangeLot(auction_id));
        }
    }, [dispatch]);

    const showImage = useCallback((primary_image) => {
        dispatch(setDataImageGallery({modalShow: true, attachments: [{...primary_image}]}))
    }, []);

    const auction_categories = useMemo(() => {
        return sortBy(all_categories, ['parent_category_id', 'display_order']);
    }, [all_categories]);

    const {show_img_lots_list} = find(user_sort_data, {user_lots_type, type});

    return {
        show_img_lots_list,
        changeLots,
        onToggleLots,
        showImage,
        auction_categories,
        type,
        user_lots_type,
        lotsData,
        promotion_color,
        phrases,
    }
}
export default useLotListFn