import React, {useEffect, useMemo} from 'react';
import {isNull} from "lodash/lang";
import {getQueryLinkParams} from "../../../../redux/lots/action";
import {useDispatch} from "react-redux";
import {deleteSessionStorageData} from "../../../../redux/crud-lot/actionCrudLot";

const InitialButtonCreateLot = (props) => {

    const {
        phrases: {create_auction, ak_au_for_create_auction,},
        visitor: {credits},
        handleShow
    } = props

    useEffect(() => {
        if (Number(credits) >= 0) {
            const createAuctionLink = document.querySelector('a.create_auction');

            if (!isNull(createAuctionLink)) {
                createAuctionLink.addEventListener('click', (event) => {
                    dispatch(deleteSessionStorageData())
                });

            }
        }

    }, [credits])

    const dispatch = useDispatch();
    const {auction_category_id} = dispatch(getQueryLinkParams());

    const button = useMemo(() => {

        if (Number(credits) < 0) {
            return (
                <a className="btn btn-danger btn-rr btn-sm callToAction OverlayTrigger"
                   href='/credits' role="button">{ak_au_for_create_auction}</a>
            )
        }

        if (Number(credits) >= 0) {

            let link = 'auction-process/newCreate';

            if (auction_category_id !== 0) {
                link += `?category_id=${auction_category_id}`
            }


            return (
                <>
                    <a className="btn btn-danger btn-rr btn-sm"
                       href={link}
                       onClick={(e) => {
                           e.preventDefault();
                           handleShow()
                       }}
                       role="button">{create_auction}</a>
                </>
            )
        }

        return null;

    }, [credits]);


    return (
        <>
            {button}
        </>
    )


}
export default InitialButtonCreateLot