import React from 'react'
import ButtonSortingSettings from "./button-sorting-settings";
import ButtonCategorySettings from "./button-category-settings";
import SortButtons from "./sort-buttons";
import Helpers from "../../../services/helpers";
import LotsTypeButtons from "./lots-type-buttons";
import useLotsFn from "../../lots/useLotsFn";


const ShopSortMenu = () => {

    const {lotsState, query} = useLotsFn()

    const sortingData = Helpers.getSortingData({...lotsState, ...query})

    return (
        <div className="col-lg-12">
            <div className="shop-top-bar flex-wrap">
                <div className="d-flex align-items-center flex-wrap">
                    <LotsTypeButtons/>
                    <SortButtons {...query} sortingData={sortingData}/>
                    <ButtonSortingSettings {...query}{...lotsState} />
                    <ButtonCategorySettings {...query}{...lotsState}/>
                </div>
            </div>
        </div>
    );
};
export default ShopSortMenu