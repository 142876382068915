import React from "react"
import ArchiveBlock from "./archive-block";
import RssButton from "./rss-button";
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import ButtonCreateLot from './button-create-lot'
const FollowCategory = React.lazy(() => import('./follow-category'));

const ArchiveFollowRssBlock = () => {

    return (
        <div className="row mb-1">
            <div className="col-12">
                <div className='section-main'>
                    <div className="m-2">
                        <div className="d-flex justify-content-center flex-wrap-reverse">
                            <div className="mr-sm-auto ">
                                <WithSuspense><ButtonCreateLot/></WithSuspense>
                            </div>
                            <div className="d-flex mb-2 mb-sm-0">
                                <ArchiveBlock/>
                                <WithSuspense><FollowCategory/></WithSuspense>
                                <RssButton/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArchiveFollowRssBlock