import React from "react";
import {slugify} from "transliteration";
import {isNil} from "lodash/lang";
import {encode} from "base-64"

export default class Helpers {

    static currencyFormat(number = 0) {
        return `${number} грн.`
    }
    static percentFormat(number = 0) {
        return `${number} %`
    }

    // параметры для сортировки данных
    static getSortingData(data) {

        const {
            phrases: {
                ak_au_ph_by_title,
                ak_au_from_a_to_z,
                ak_au_from_z_to_a,
                ak_au_ph_by_price,
                ak_au_ph_descending_price,
                ak_au_ph_ascending_price,
                ak_au_at_a_blitz_price,
                ak_au_ph_by_time,
                ak_au_ph_descending,
                ak_au_ph_ascending,
                ak_au_ph_by_bits,
                ak_au_ph_by_followers,
                ak_au_ph_date_creation,
                ak_au_ph_top_bidder,
                ak_au_ph_by_seller,
                ak_au_ph_by_sale_date,
                ak_au_ph_by_bet_date,
                ak_au_ph_by_bet_amount,
                ak_au_ph_time_left,
                ak_au_end_date_descending,
                ak_au_end_date_ascending,
                ak_au_ph_In_count_ascending,
                ak_au_ph_In_count_descending,
                ak_au_ph_date_creation_descending,
                ak_au_ph_date_creation_ascending,
                ak_au_by_quantity_of_product
            },
            visitor: {
                user_id: visitor_user_id,
                permissions: {
                    auctions: {
                        RestoreLots,
                        view_all_bids,
                        deleteLots
                    }
                }
            },
            type,
            user_id,
            user_lots_type,
            lotType
        } = data;


        const default_sort =
            [
                {// 1) "По заголовку - от А до Я"
                    orderSelectTitle: ak_au_ph_by_title,
                    order: 'title',
                    sortOrder: 'title',
                    defaultDirection: {desc: ak_au_from_z_to_a, asc: ak_au_from_a_to_z},
                    titleSelectDirection: ak_au_from_a_to_z,
                    direction: 'asc',
                    view: () => {
                        let types = ['for_sales', 'sale', 'awaiting_shipment', 'send', 'awaiting_confirmation', 'no_pay',
                            'waitforpay', 'take', 'watch_lots', 'no_sale', 'finalized', 'inactive_copy',
                            'all_user_bets', 'awaiting_payment', 'nosale', 'vacation', 'unfinished'];
                        return types.includes(type);
                    }
                },
                {// 2) "По цене - По убыванию цены"
                    orderSelectTitle: ak_au_ph_by_price,
                    order: 'price',
                    sortOrder: 'union_price',
                    defaultDirection: {desc: ak_au_ph_descending_price, asc: ak_au_ph_ascending_price},
                    titleSelectDirection: ak_au_ph_descending_price,
                    direction: 'desc',
                    view: () => {
                        let types = ['for_sales', 'sale', 'awaiting_shipment', 'send', 'awaiting_confirmation', 'no_pay',
                            'waitforpay', 'take', 'watch_lots', 'no_sale', 'finalized', 'nosale', 'finalized',
                            'awaiting_payment', 'vacation', 'inactive_copy', 'unfinished'];
                        return types.includes(type);
                    }

                },
                {// 3) "По блиц-цене - По убыванию цены"
                    orderSelectTitle: ak_au_at_a_blitz_price,
                    order: 'buyout_price',
                    sortOrder: 'buyout_price',
                    defaultDirection: {desc: ak_au_ph_descending_price, asc: ak_au_ph_ascending_price},
                    titleSelectDirection: ak_au_ph_descending_price,
                    direction: 'desc',
                    view: () => {
                        let types = ['for_sales', 'watch_lots', 'no_pay', 'nosale', 'finalized', 'vacation', 'inactive_copy'];
                        return types.includes(type);

                    }
                },
                {// 4) "По дате окончания - По убыванию"
                    orderSelectTitle: ak_au_ph_by_time,
                    order: 'time',
                    sortOrder: 'expiration_date',
                    defaultDirection: (user_lots_type === 'archive_lots')
                        ? {desc: ak_au_ph_date_creation_descending, asc: ak_au_ph_date_creation_ascending}
                        : {desc: ak_au_end_date_descending, asc: ak_au_end_date_ascending},
                    titleSelectDirection: ak_au_end_date_ascending,
                    direction: 'asc',
                    view: () => {
                        let types = ['for_sales', 'watch_lots', 'all_user_bets', 'no_pay', 'vacation',
                            'nosale', 'finalized', 'inactive_copy', 'unfinished'];
                        return types.includes(type);
                    }
                },
                {// 5) "По ставкам - По убыванию"
                    orderSelectTitle: ak_au_ph_by_bits,
                    order: 'bids',
                    sortOrder: 'bids',
                    defaultDirection: {desc: ak_au_ph_In_count_descending, asc: ak_au_ph_In_count_ascending},
                    titleSelectDirection: ak_au_ph_In_count_descending,
                    direction: 'desc',
                    view: () => {

                        let types = ['for_sales', 'watch_lots', 'no_sale', 'vacation'];

                        return (types.includes(type) || user_lots_type === 'archive_lots')
                    }
                },
                {// 6) "По отслеживаниям - по убыванию"
                    orderSelectTitle: ak_au_ph_by_followers,
                    order: 'watchs',
                    sortOrder: 'count_lot_watch',
                    defaultDirection: {desc: ak_au_ph_In_count_descending, asc: ak_au_ph_In_count_ascending},
                    titleSelectDirection: ak_au_ph_In_count_descending,
                    direction: 'desc',
                    view: () => {
                        let types = ['for_sales', 'watch_lots', 'vacation'];
                        return types.includes(type);
                    }
                },
                {// 7) "По времени создания - По убыванию"
                    orderSelectTitle: ak_au_ph_date_creation,
                    order: 'date',
                    sortOrder: 'placement_date',
                    defaultDirection: {desc: ak_au_ph_date_creation_descending, asc: ak_au_ph_date_creation_ascending},
                    titleSelectDirection: ak_au_ph_date_creation_descending,
                    direction: 'desc',
                    view: () => {
                        let types = ['for_sales', 'watch_lots', 'sale', 'no_sale', 'no_pay', 'nosale', 'finalized', 'awaiting_confirmation', 'awaiting_shipment', 'awaiting_payment', 'vacation', 'inactive_copy'];
                        return types.includes(type);
                    }
                },
                {// 8) "По Покупателю - от А до Я"
                    orderSelectTitle: ak_au_ph_top_bidder,
                    order: 'top_bidder',
                    sortOrder: 'top_bidder_name',
                    defaultDirection: {desc: ak_au_from_z_to_a, asc: ak_au_from_a_to_z},
                    titleSelectDirection: ak_au_from_a_to_z,
                    direction: 'asc',
                    view: () => {
                        let types = ['sale', 'awaiting_shipment', 'waitforpay', 'no_pay',
                            'awaiting_payment', 'awaiting_confirmation'];

                        if (user_lots_type === 'archive_lots') {
                            return false;
                        }

                        if (type === 'sale' && user_id === visitor_user_id && user_lots_type === 'purchases') {
                            return false;
                        }

                        return (
                            types.includes(type)
                            && (visitor_user_id === user_id || deleteLots || RestoreLots || view_all_bids)
                        );
                    }

                },
                {// 9) "По дате продажи (колонка - date_of_sale) - По убыванию"
                    orderSelectTitle: ak_au_ph_by_time,
                    order: 'date_of_sale',
                    sortOrder: 'date_of_sale',
                    defaultDirection: {desc: ak_au_ph_date_creation_descending, asc: ak_au_ph_date_creation_ascending},
                    titleSelectDirection: ak_au_ph_date_creation_descending,
                    direction: 'desc',
                    view: () => {
                        return (type === 'sale')
                    }
                },
                {// 10) "По первой дате продажи, время выйграша лота (колонка - first_history_date) - По убыванию"
                    orderSelectTitle: ak_au_ph_by_sale_date,
                    order: 'first_history_date',
                    sortOrder: 'first_history_date',
                    defaultDirection: {desc: ak_au_ph_descending, asc: ak_au_ph_ascending},
                    titleSelectDirection: ak_au_ph_descending,
                    direction: 'desc',
                    view: () => {
                        let types = ['no_sale'];
                        return (types.includes(type))
                    }
                },
                {// 11) "время ожидания оплаты (колонка - waitforpay_date) - По возрастанию"
                    orderSelectTitle: ak_au_ph_time_left,
                    order: 'waitforpay_date',
                    sortOrder: 'waitforpay_date',
                    defaultDirection: {desc: ak_au_ph_descending, asc: ak_au_ph_ascending},
                    titleSelectDirection: ak_au_ph_ascending,
                    direction: 'asc',
                    view: () => {
                        let types = ['awaiting_payment', 'waitforpay'];
                        return types.includes(type);
                    }
                },
                {// 12) "время ожидания отправки (колонка - send_date) - По возрастанию"
                    orderSelectTitle: ak_au_ph_time_left,
                    order: 'send_date',
                    sortOrder: 'send_date',
                    defaultDirection: {desc: ak_au_ph_descending, asc: ak_au_ph_ascending},
                    titleSelectDirection: ak_au_ph_ascending,
                    direction: 'asc',
                    view: () => {
                        let types = ['awaiting_shipment', 'send'];
                        return types.includes(type);
                    }
                },
                {// 13) "время ожидания получения (колонка - take_date) - По возрастанию"
                    orderSelectTitle: ak_au_ph_time_left,
                    order: 'take_date',
                    sortOrder: 'take_date',
                    defaultDirection: {desc: ak_au_ph_descending, asc: ak_au_ph_ascending},
                    titleSelectDirection: ak_au_ph_ascending,
                    direction: 'asc',
                    view: () => {
                        let types = ['awaiting_confirmation', 'take'];
                        return types.includes(type);
                    }
                },
                {// 14) "По продавцу - от А до Я"
                    orderSelectTitle: ak_au_ph_by_seller,
                    order: 'users',
                    sortOrder: 'username',
                    defaultDirection: {desc: ak_au_from_z_to_a, asc: ak_au_from_a_to_z},
                    titleSelectDirection: ak_au_ph_descending,
                    direction: 'asc',
                    view: () => {
                        let types = ['take', 'watch_lots', 'send', 'no_sale'];

                        return (
                            types.includes(type)
                            && (visitor_user_id === user_id || deleteLots || RestoreLots || view_all_bids)
                        );
                    }
                },
                {// 15) "время ожидания получения (колонка - take_date) - По возрастанию"
                    orderSelectTitle: ak_au_ph_by_bet_date,
                    order: 'bid_date',
                    sortOrder: 'bid_date',
                    defaultDirection: {desc: ak_au_ph_descending, asc: ak_au_ph_ascending},
                    titleSelectDirection: ak_au_ph_descending,
                    direction: 'desc',
                    view: () => {
                        return (type === 'all_user_bets')
                    }
                },
                {// 16) "По размеру ставки - По убыванию цены"
                    orderSelectTitle: ak_au_ph_by_bet_amount,
                    order: 'price',
                    sortOrder: 'amount',
                    defaultDirection: {desc: ak_au_ph_descending_price, asc: ak_au_ph_ascending_price},
                    titleSelectDirection: ak_au_ph_descending_price,
                    direction: 'desc',
                    view: () => {
                        let types = ['all_user_bets'];
                        return types.includes(type);
                    }

                },
                {// 16) "По кол-ву товара- По убыванию"
                    orderSelectTitle: ak_au_by_quantity_of_product,
                    order: 'lot_quantity',
                    sortOrder: 'balance',
                    defaultDirection: {desc: ak_au_ph_In_count_descending, asc: ak_au_ph_In_count_ascending},
                    titleSelectDirection: ak_au_ph_In_count_descending,
                    direction: 'desc',
                    view: () => {
                        let types = ['for_sales'];
                        return types.includes(type) && user_lots_type === 'user_lots' && lotType === 'multi';
                    }

                },
            ];

        let sort = [];

        default_sort.map((item) => {
            if (item.view()) {
                sort.push(item);
            }
            return true;
        });

        return sort
    }

    static getUserLink({username, user_id}) {

        return (
            <a className="username"
               itemProp="name"
               dir="auto"
               href={`/members/${Helpers.getUserVariableReference({username, user_id})}`}>{username}</a>
        )
    }

    //данные для сайдбара. При выборе раздела лотов меняеться заголовки и хлебные крошки
    static getNavigationData(phrases) {

        const {
            your_bids,
            ak_au_your_bids,
            auctions_your_following_lots,
            auctions_your_purchases_awaiting_payment,
            auctions_your_purchases_awaiting_seller_payment,
            auctions_your_purchases_are_pending,
            auctions_all_your_completed_purchases,
            auctions_your_not_purchased_lots,
            ak_au_your_lots_for_sale,
            ak_au_your_lots_awaiting_buyers_payment,
            ak_au_your_lots_awaiting_your_submission,
            ak_au_your_lots_awaiting_receipt,
            ak_au_my_unpaid_lots,
            ak_lots_you_sale_lots,
            ak_au_all_your_completed_lots,
            ak_au_Inactive_copies_of_my_lots,
            ak_au_my_frozen_lots,
            ak_au_all_my_unsold_lots,
            ak_au_for_sale_user_lots,
            ak_lots_user_sale,
            auctions_all_following_users_lots,
            auctions_all_users_bets,
            auctions_all_biddings_of_user,
            auctions_waiting_for_buyers_payment,
            auctions_waiting_for_seller_sent,
            auctions_waiting_received_by_buyer,
            ak_au_all_completed_user_purchases,
            ak_au_user_unsold_lots,
            ak_au_users_lots_awaiting_buyers_payment,
            auctions_all_not_purchased_users_lots,
            ak_au_user_unpaid_lots,
            ak_au_user_completed_lots,
            ak_au_user_lots_awaiting_your_submission,
            ak_au_user_lots_awaiting_receipt,
            ak_au_user_Inactive_copies,
            ak_au_user_frozen_lots

        } = phrases

        return [
            {
                title: your_bids,
                user_lots_type: 'user_bidding',
                type: 'all_user_bets',
                order: 'bid_date',
                owner_title: auctions_all_users_bets
            },
            {
                title: ak_au_your_bids,
                user_lots_type: 'user_bidding',
                type: 'for_sales',
                order: 'title',
                owner_title: auctions_all_biddings_of_user
            },
            {
                title: auctions_your_following_lots,
                user_lots_type: 'user_lots',
                type: 'watch_lots',
                order: 'time',
                owner_title: auctions_all_following_users_lots
            },
            {
                title: auctions_your_purchases_awaiting_payment,
                user_lots_type: 'purchases',
                type: 'waitforpay',
                order: 'waitforpay_date',
                owner_title: auctions_waiting_for_buyers_payment

            },
            {
                title: auctions_your_purchases_awaiting_seller_payment,
                user_lots_type: 'purchases',
                type: 'send',
                order: 'send_date',
                owner_title: auctions_waiting_for_seller_sent

            },
            {
                title: auctions_your_purchases_are_pending,
                user_lots_type: 'purchases',
                type: 'take',
                order: 'take_date',
                owner_title: auctions_waiting_received_by_buyer

            },
            {
                title: auctions_all_your_completed_purchases,
                user_lots_type: 'purchases',
                type: 'sale',
                order: 'date_of_sale',
                owner_title: ak_au_all_completed_user_purchases

            },
            {
                title: auctions_your_not_purchased_lots,
                user_lots_type: 'user_bidding',
                type: 'no_sale',
                order: 'first_history_date',
                owner_title: auctions_all_not_purchased_users_lots
            },
            {
                title: ak_au_your_lots_for_sale,
                user_lots_type: 'user_lots',
                type: 'for_sales',
                order: 'time',
                owner_title: ak_au_for_sale_user_lots

            },
            {
                title: ak_au_your_lots_awaiting_buyers_payment,
                user_lots_type: 'user_lots',
                type: 'awaiting_payment',
                order: 'waitforpay_date',
                owner_title: ak_au_users_lots_awaiting_buyers_payment

            },
            {
                title: ak_au_your_lots_awaiting_your_submission,
                user_lots_type: 'user_lots',
                type: 'awaiting_shipment',
                order: 'send_date',
                owner_title: ak_au_user_lots_awaiting_your_submission

            },
            {
                title: ak_au_your_lots_awaiting_receipt,
                user_lots_type: 'user_lots',
                type: 'awaiting_confirmation',
                order: 'take_date',
                owner_title: ak_au_user_lots_awaiting_receipt

            },
            {
                title: ak_au_my_unpaid_lots,
                user_lots_type: 'user_lots',
                type: 'no_pay',
                order: 'time',
                owner_title: ak_au_user_unpaid_lots
            },
            {
                title: ak_lots_you_sale_lots,
                user_lots_type: 'user_lots',
                type: 'sale',
                order: 'date_of_sale',
                owner_title: ak_lots_user_sale

            },
            {
                title: ak_au_all_my_unsold_lots,
                user_lots_type: 'user_lots',
                type: 'nosale',
                order: 'time',
                owner_title: ak_au_user_unsold_lots

            },
            {
                title: ak_au_all_your_completed_lots,
                user_lots_type: 'user_lots',
                type: 'finalized',
                order: 'time',
                owner_title: ak_au_user_completed_lots

            },
            {
                title: ak_au_Inactive_copies_of_my_lots,
                user_lots_type: 'user_lots',
                type: 'inactive_copy',
                order: 'time',
                owner_title: ak_au_user_Inactive_copies

            },
            {
                title: ak_au_my_frozen_lots,
                user_lots_type: 'user_lots',
                type: 'vacation',
                order: 'time',
                owner_title: ak_au_user_frozen_lots
            },
        ]
    }

    static makeBreadCrumb(queryBCrumbs, lotsBreadCrumbs) {
        let newCrumbs = [];

        let x = queryBCrumbs.children
        for (let i = 0; i < x.length; i++) {
            if (x.item(i).classList.contains("homeCrumb")) {
                newCrumbs.push(x.item(i));
            }
        }

        const calassCrust = 'crust';
        const calassArrow = 'arrow';

        lotsBreadCrumbs.map((item) => {
            var spanMain = document.createElement('span');
            spanMain.className = calassCrust;
            spanMain.appendChild(document.createTextNode('\n\t\t\t\t\t\t'));

            var a = document.createElement('a');

            a.setAttribute('href', item.href);
            a.onclick = (e) => {
                e.preventDefault();
                if (typeof item.action !== 'undefined') {
                    item.action()
                }
            }
            a.className = 'crumb';

            var span_a = document.createElement('span');
            span_a.innerHTML = item.value;

            a.appendChild(span_a);

            spanMain.appendChild(a);
            spanMain.appendChild(document.createTextNode('\n\t\t\t\t\t\t'));

            var span_arrow = document.createElement('span');
            span_arrow.className = calassArrow;

            var span = document.createElement('span');
            span.innerHTML = '&gt;';

            span_arrow.appendChild(span);

            spanMain.appendChild(span_arrow);
            newCrumbs.push(spanMain);

            return true;
        });

        queryBCrumbs.innerHTML = ''
        newCrumbs.map((item) => {
            queryBCrumbs.appendChild(item);
            return true;
        });
    }

// открытый закрытый блок (ползунок открытия закрытия)
// show = true or false
    static getHeaderBlockToggleIcon(show) {
        return (show)
            ?
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-toggle2-on" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7 5H3a3 3 0 0 0 0 6h4a4.995 4.995 0 0 1-.584-1H3a2 2 0 1 1 0-4h3.416c.156-.357.352-.692.584-1z"/>
                <path d="M16 8A5 5 0 1 1 6 8a5 5 0 0 1 10 0z"/>
            </svg>
            :
            <svg width="1em" height="1em" viewBox="0 0 16 16" className="bi bi-toggle2-off" fill="currentColor"
                 xmlns="http://www.w3.org/2000/svg">
                <path d="M9 11c.628-.836 1-1.874 1-3a4.978 4.978 0 0 0-1-3h4a3 3 0 1 1 0 6H9z"/>
                <path fillRule="evenodd" d="M5 12a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0 1A5 5 0 1 0 5 3a5 5 0 0 0 0 10z"/>
            </svg>
    }

    static getCategoryTreeHeaderToggleIcon(show) {
        return (show)
            ?
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                 className="bi bi-dash-circle" viewBox="0 0 17 17">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
            </svg>
            :
            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor"
                 className="bi bi-plus-circle" viewBox="0 0 17 17">
                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
            </svg>
    }

    static getDepth(depth = 0) {

        switch (depth) {
            case 0:
                return depth + 1;
            case 1:
                return depth + 2;
            case 2:
                return depth + 3;
            case 3:
                return depth + 3;
            default:
                return depth;
        }
    }

    static setImg = (primary_image, thumbnail = false) => {

        if (primary_image === undefined) {
            return null
        }
        const {thumbnailUrl, viewUrl} = primary_image;

        const img = (thumbnail) ? thumbnailUrl : viewUrl;
        const domain = `${this.getBaseUrl()}/`
        return primary_image ? domain + img : null;
    };

    static getBaseUrl = () => {
        return (window.location.hostname === 'localhost') ? 'https://dev.reibert.info' : '';
    }

    static getDefaultQueryParams = () => {

        let obj = {
            _xfNoRedirect: 1,
            _xfResponseType: 'json',
            _xfRequestUri: document.location.pathname + document.location.search
        }

        const csrfToken = document.querySelector('input[name="_xfToken"]').value;

        if (csrfToken.length > 0) {
            obj = {...obj, _xfToken: csrfToken}
        }

        return obj;
    }

    static getHeaders = () => {

        let headers = {
            'Accept': 'application/json, text/javascript, */*; q=0.01',
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        };

        if (window.location.hostname === 'localhost') {
            const username = 'dev'
            const password = 'dev112014'
            headers = {...headers, 'Authorization': `Basic ${encode(`${username}:${password}`)}`}
        }

        return headers;

    }

    static getUserAuctionSorting = () => {
        const auctionSortinData = JSON.parse(localStorage.getItem('auctionSorting'));

        if (isNil(auctionSortinData)) {
            return {user_lots_type: 'auction_lots', type: 'for_sales', direction: 'desc', order: 'date'}
        }

        return auctionSortinData[0]
    }

    static getUserSaleLotsSorting = () => {
        const auctionSortinData = JSON.parse(localStorage.getItem('userSaleLotsSorting'));

        if (isNil(auctionSortinData)) {
            return {user_lots_type: 'user_lots', type: 'for_sales', direction: 'desc', order: 'time'}
        }

        return auctionSortinData[0]
    }

    static getCategoryVariableReference = ({category_title, auction_category_id}) => {
        return `${slugify(category_title)}.${auction_category_id}/`;
    }

    static getLotVariableReference = ({title, auction_id}) => {
        return `${slugify(title)}.${auction_id}`;
    }

    static getUserVariableReference = ({username, user_id}) => {
        return `${slugify(username)}.${user_id}`;
    }

    static removeTagsText = (message) => {
        return message.replace(/(<([^>]+)>)/ig, "");
    }
}