import React, {useContext} from 'react'
import {truncate} from "lodash/string";
import Helpers from "../../../services/helpers";
import {push} from "connected-react-router";
import {useDispatch, useSelector} from "react-redux";
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const LinkTitle = () => {

    const dispatch = useDispatch();
    const {lotData} = useContext(lotContext);
    const {title, lot_type, balance, isdel} = lotData;

    const { visitor: {permissions: {auctions: {see_new = false}}},
        phrases: {ak_au_phrase_lot_balance}} = useSelector(state => state.lots);

    const link = `/lots/${Helpers.getLotVariableReference(lotData)}`;
    const title_short = truncate(title, {'length': 80, 'separator': '...'});
    const linkTitle = `${title_short} ${lot_type && balance > 0 ? ak_au_phrase_lot_balance.replace(/\{balance\}/, balance) : ''}`

    const handleTitleClick = (event) => {
        if (see_new) { //todo delete this
            event.preventDefault();
            dispatch(push(link));
        }
    }

    return (
        <a className={`lot-title ${isdel ? 'text-danger' : ''}`}
           onClick={(e) => handleTitleClick(e)}
           href={link}>{linkTitle}</a>
    )
}
export default LinkTitle

