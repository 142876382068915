import {createMatchSelector, push} from "connected-react-router";
import {split} from "lodash/string";
import {filter, find, includes, orderBy} from "lodash/collection";
import {findIndex} from "lodash/array";
import {isEmpty, isNull, isUndefined} from "lodash/lang"

import {
    SET_CRUD_DATA,
    SET_DELIVERY_PARAMS,
    SET_ERROR_DELIVERY,
    SET_ERROR_PAYMENT,
    SET_ERROR_SUBMIT_FORM,
    SET_IMAGES,
    SET_IMAGE_ORDER,
    SET_LOT_CATEGORY_ID,
    SET_LOT_OPTIONS,
    SET_LOT_PARAMS,
    SET_LOT_PREFIX,
    SET_PAYMENT_PARAMS,
    SET_PROMOTION_DATA
} from "../types";
import CrudApiService from "../../services/crud-api-service";
import {keys} from "lodash/object";
import Helpers from "../../services/helpers";

const crudApiService = new CrudApiService();

export function getCreateData() {

    return (dispatch, getState) => {

        const crud = getState().crud;

        let attachmentParams = JSON.parse(sessionStorage.getItem('attachmentParams'));

        const hash = (isNull(attachmentParams)) ? '' : attachmentParams.hash;

        crudApiService.getCreateData({hash})
            .then((json) => {

                const {auction} = json;

                if ('error' in json) {
                    alert(json.error[0]);
                    return
                }

                const auction_default_data = crud.auction;
                const endDate = new Date(json.minimumNumberDays * 1000);
                let storage_auction = JSON.parse(sessionStorage.getItem('auction'));

                if (!isNull(storage_auction)) {
                    storage_auction.endDate = new Date(storage_auction.endDate)
                }

                const objAuction = {...auction_default_data, ...auction, endDate, ...storage_auction};

                let storage_delivery_methods = JSON.parse(sessionStorage.getItem('delivery_methods'));
                storage_delivery_methods = !isNull(storage_delivery_methods) ? storage_delivery_methods : [];

                let delivery_methods = orderBy(json.delivery_methods, ['delivery_id'], ['asc']);

                delivery_methods = filter(delivery_methods, (o) => {
                    return o.delivery_active
                });

                delivery_methods = delivery_methods.map((value) => {
                    const storage_method = find(storage_delivery_methods, {'delivery_id': value.delivery_id});

                    return {
                        ...value,
                        delivery_nal_pay: (isUndefined(storage_method)) ? false : storage_method.delivery_nal_pay,
                        delivery_pred_pay: (isUndefined(storage_method)) ? true : storage_method.delivery_pred_pay,
                        enabled: (isUndefined(storage_method)) ? (value.delivery_active) ? 1 : 0 : storage_method.enabled,
                        delivery_error: (isUndefined(storage_method)) ? false : storage_method.delivery_error
                    }
                });

                let storage_payment_methods = JSON.parse(sessionStorage.getItem('payment_methods'));
                storage_payment_methods = !isNull(storage_payment_methods) ? storage_payment_methods : [];
                let payment_methods = orderBy(json.payment_methods, ['payment_id'], ['asc']);

                payment_methods = filter(payment_methods, (o) => {
                    return o.payment_active
                });

                payment_methods = payment_methods.map(value => {
                    const storage_method = find(storage_payment_methods, {'payment_id': value.payment_id});

                    return {
                        ...value,
                        enabled: (isUndefined(storage_method)) ? (value.payment_active) ? 1 : 0 : storage_method.enabled,
                        paymetn_error: (isUndefined(storage_method)) ? false : storage_method.paymetn_error
                    }
                });

                const deliveryError = JSON.parse(sessionStorage.getItem('deliveryError'));
                const deliveryItemError = JSON.parse(sessionStorage.getItem('deliveryItemError'));
                const paymentError = JSON.parse(sessionStorage.getItem('paymentError'));
                const paymentItemError = JSON.parse(sessionStorage.getItem('paymentItemError'));
                const primary_image_id = JSON.parse(sessionStorage.getItem('primary_image_id'));

                attachmentParams = (isNull(attachmentParams)) ? json.attachmentParams : attachmentParams;
                let attachments = getSortImages(json.attachments);

                if (attachments.length > 0) {

                    let index = 0;

                    if (!isNull(primary_image_id)) {
                        index = findIndex(attachments, {'attachment_id': primary_image_id})

                        if (index === -1) {
                            index = 0;
                        }
                    }

                    attachments[index].primary_image = 1;
                }

                let categorySettings = orderBy(json.categorySettings, ['auction_category_id'], ['asc']);

                const categories = orderBy(json.categories, ['lft'], ['asc']);

                const selectedPromotionType = JSON.parse(sessionStorage.getItem('selectedPromotionType'));

                const promotionObj = (!isEmpty(selectedPromotionType)) ? {
                    promotionShow: true,
                    selectedPromotionType
                } : {}

                const imageOrder = JSON.parse(sessionStorage.getItem('imageOrder'));

                const obj = {
                    ...json,
                    ...promotionObj,
                    loading: false,
                    auction: objAuction,
                    delivery_methods,
                    payment_methods,
                    deliveryError,
                    deliveryItemError,
                    paymentError,
                    paymentItemError,
                    attachmentParams,
                    attachments,
                    imageOrder,
                    categorySettings,
                    categories
                };

                dispatch({type: SET_CRUD_DATA, payload: obj});
            });
    }
}


/**
 * @param {{auction_id: number!, create_copy:number?, reedit:number?}} obj
 */
export function getEditData(obj) {

    return (dispatch, getState) => {
        const crud = getState().crud;

        crudApiService.getEditData(obj)
            .then((json) => {

                if ('error' in json) {
                    alert(json.error[0]);
                    return
                }

                const {auction, end_date} = json;
                const auction_default_data = crud.auction;

                const {min_bid, buyout_price} = auction;

                const endDate = new Date(end_date * 1000);

                const objAuction = {
                    ...auction_default_data,
                    ...auction,
                    starting_bid: min_bid,
                    buyout_price: buyout_price ? buyout_price : null,
                    endDate
                };

                let delivery_methods = orderBy(json.delivery_methods, ['delivery_id'], ['asc']);

                delivery_methods = filter(delivery_methods, (o) => {
                    return !o.disabled
                });

                delivery_methods = delivery_methods.map((value) => {
                    return {
                        ...value,
                        enabled: (value.delivery_active) ? 1 : 0,
                        delivery_nal_pay: !!(value.delivery_nal_pay),
                        delivery_pred_pay: !!(value.delivery_pred_pay),
                    }
                });

                let payment_methods = orderBy(json.payment_methods, ['payment_id'], ['asc']);

                payment_methods = filter(payment_methods, (o) => {
                    return !o.disabled
                });

                payment_methods = payment_methods.map(value => {
                    return {
                        ...value,
                        enabled: (value.payment_active) ? 1 : 0
                    }
                });

                const categories = orderBy(json.categories, ['lft'], ['asc']);

                let categorySettings = orderBy(json.categorySettings, ['auction_category_id'], ['asc']);

                let imageOrder = orderBy(objAuction.image_order, ['order'], ['asc']);

                sessionStorage.setItem('imageOrder', JSON.stringify(imageOrder));
                let attachments = getSortImages(json.attachments);

                const obj = {
                    ...json,
                    delivery_methods,
                    payment_methods,
                    loading: false,
                    auction: objAuction,
                    categorySettings,
                    categories,
                    imageOrder,
                    attachments
                }

                dispatch({type: SET_CRUD_DATA, payload: obj});
            });
    }
}

export function setLotTitle(title) {
    return (dispatch, getState) => {
        const {auction} = getState().crud
        dispatch({type: SET_LOT_PARAMS, payload: {...auction, title}});
    }
}

export function setLotCategory(auction_category_id) {
    return (dispatch, getState) => {
        const {auction} = getState().crud
        const {prefix_id} = auction;

        dispatch({type: SET_LOT_CATEGORY_ID, payload: {...auction, auction_category_id}});

        if (prefix_id !== 0) {
            dispatch(setLotPrefix(0));
        }
    }
}

export function setLotParams(obj) {
    return (dispatch, getState) => {

        const {auction} = getState().crud
        dispatch({type: SET_LOT_OPTIONS, payload: {...auction, ...obj}});
    }
}

export function setLotType(lot_type) {
    return (dispatch, getState) => {
        const {auction} = getState().crud
        dispatch({type: SET_LOT_PARAMS, payload: {...auction, lot_type}});
    }
}

export function setLotStartBid(value) {
    return (dispatch, getState) => {
        const {auction} = getState().crud

        const starting_bid = (value === '') ? null : value;

        dispatch({type: SET_LOT_PARAMS, payload: {...auction, starting_bid}});
    }
}

export function setLotBuyNow(value) {
    return (dispatch, getState) => {
        const {auction} = getState().crud

        const buyout_price = (value === '') ? null : value;

        dispatch({type: SET_LOT_PARAMS, payload: {...auction, buyout_price}});
    }
}

export function setLotQuantity(value) {
    return (dispatch, getState) => {
        const {auction} = getState().crud

        const auction_amount = (value === '') ? 0 : value;

        dispatch({type: SET_LOT_PARAMS, payload: {...auction, auction_amount}});
    }
}

export function setLotEndDate(value) {
    return (dispatch, getState) => {
        const {auction, minimumNumberDays} = getState().crud

        const endDate = (isNull(value)) ? new Date(minimumNumberDays * 1000) : value;

        dispatch({type: SET_LOT_PARAMS, payload: {...auction, endDate}});
    }
}

export function setDescription(message) {
    return (dispatch, getState) => {
        const {auction} = getState().crud
        dispatch({type: SET_LOT_PARAMS, payload: {...auction, message}});
    }
}

export function setDisadvantage(disadvantage) {
    return (dispatch, getState) => {
        const {auction} = getState().crud
        dispatch({type: SET_LOT_PARAMS, payload: {...auction, disadvantage}});
    }
}

export function changeDelivery(delivery_id, operation) {
    return (dispatch, getState) => {
        const {delivery_methods} = getState().crud

        const index = findIndex(delivery_methods, {'delivery_id': delivery_id});

        const item = delivery_methods[index];

        const getData = () => {
            switch (operation) {
                case 'delivery_pred_pay':
                    return {delivery_pred_pay: !item.delivery_pred_pay}
                case 'delivery_nal_pay':
                    return {delivery_nal_pay: !item.delivery_nal_pay}
                case 'enabled':
                    return {enabled: !item.enabled}
                case 'error':
                    return {delivery_error: !item.delivery_error}
                default:
                    return {}
            }
        }

        const newEl = {...item, ...getData()}

        const newArr = [...delivery_methods.slice(0, index), ...delivery_methods.slice(index + 1)];

        let newDeliveryMethods = [...newArr, newEl];

        newDeliveryMethods = orderBy(newDeliveryMethods, ['delivery_id'], ['asc']);

        dispatch({type: SET_DELIVERY_PARAMS, payload: newDeliveryMethods});
    }
}

export function setErrorDelivery(value) {
    return (dispatch, getState) => {
        const {deliveryError} = getState().crud

        if (value !== deliveryError) {
            dispatch({type: SET_ERROR_DELIVERY, payload: value});
        }
    }
}

export function changePayment(payment_id) {
    return (dispatch, getState) => {
        const {payment_methods} = getState().crud

        const index = findIndex(payment_methods, {'payment_id': payment_id});

        const item = payment_methods[index];
        const newEl = {...item, enabled: !item.enabled}

        const newArr = [...payment_methods.slice(0, index), ...payment_methods.slice(index + 1)];

        let newPaymentMethods = [...newArr, newEl];

        newPaymentMethods = orderBy(newPaymentMethods, ['payment_id'], ['asc']);

        dispatch({type: SET_PAYMENT_PARAMS, payload: newPaymentMethods});
    }
}

export function setErrorPayment(value) {
    return (dispatch, getState) => {
        const {paymentError} = getState().crud

        if (value !== paymentError) {
            dispatch({type: SET_ERROR_PAYMENT, payload: value});
        }
    }
}

export function setImages(attachments) {
    return (dispatch) => {

        const imageOrder = attachments.map((item, index) => {
            const {attachment_id} = item;
            return {'attachment_id': attachment_id, 'order': index + 1}
        });

        sessionStorage.setItem('imageOrder', JSON.stringify(imageOrder));

        dispatch({type: SET_IMAGES, payload: attachments})
        dispatch({type: SET_IMAGE_ORDER, payload: imageOrder})
    }
}

export function uploadImages(attachments) {
    return async (dispatch, getState) => {
        const chunkSize = 10;
        const dividedArrays = [];

        while (attachments.length) {
            dividedArrays.push(attachments.splice(0, chunkSize));
        }

        const {attachmentParams, auction: {auction_id}} = getState().crud;

        let attachmentArr = {};

        for (const arr of dividedArrays) {
            const formData = new FormData();

            for (const item of arr) {
                formData.append('images[]', item);
            }

            formData.append('hash', attachmentParams.hash);

            if (!isNull(auction_id)) {
                formData.append('auction_id', auction_id);
            }
            formData.append('_xfConfirm', '1');

            const json = await crudApiService.uploadImages(formData);

            const {status} = json

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (status === 'ok') {
                attachmentArr = json.attachments;
            }
        }

        const newAttachments = orderBy(attachmentArr, ['name'], ['asc']);

        dispatch(setImages(newAttachments));
        return true;
    };
}

export function alertError(name) {
    return (dispatch, getState) => {
        const {phrases: {ak_au_image_cannot_be_loaded}} = getState().crud
        alert(ak_au_image_cannot_be_loaded.replace(/\{name\}/, name));
    }
}

export function deleteImages(attachment) {
    return (dispatch, getState) => {
        const {temp_hash, attachment_id, content_type} = attachment;
        const obj = {temp_hash, attachment_id, content_type};
        crudApiService.deleteImage(obj).then((json) => {
            const {_redirectStatus} = json

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                return true;
            }
        });
    }
}

export function setLotPrefix(prefix_id) {
    return (dispatch, getState) => {
        const {auction} = getState().crud
        dispatch({type: SET_LOT_PREFIX, payload: {...auction, prefix_id}})
    }
}

export function deleteSessionStorageData() {
    return () => {
        const storageParams = [
            'attachmentParams',
            'auction',
            'delivery_methods',
            'payment_methods',
            'paymentError',
            'deliveryError',
            'imageOrder',
            'selectedPromotionType',
            'loading'];

        storageParams.map((item) => {
            sessionStorage.removeItem(item);
            return true;
        });
    }
}

export function getCategoryIdFromLink() {
    return (dispatch, getState) => {

        const state = getState();

        const getCategoryId = () => {
            const path = {path: "/auction-process/create/:auction_category_id"};

            let match = createMatchSelector(path)(state);

            const matchParams = (!isNull(match)) ? match.params : undefined;

            if (!isUndefined(matchParams)) {
                const value = !isUndefined(matchParams['auction_category_id'])
                    ? matchParams['auction_category_id'] : undefined;

                if (matchParams.hasOwnProperty('auction_category_id')) {
                    const getParsXenParams = (item) => {
                        const arr = split(item, '.');
                        return arr[arr.length - 1];
                    }

                    if (!isUndefined(value)) {
                        return parseInt(getParsXenParams(value))
                    }
                }
            }
            return 0;
        }

        const auction_category_id = getCategoryId();
        dispatch(setLotCategory(auction_category_id));
    }
}

export function validationData() {
    return (dispatch, getState) => {
        const {
            auction,
            attachments,
            phrases,
            promotionShow,
            prefixes,
            selectedPromotionType,
            deliveryError,
            delivery_methods,
            payment_methods,
            paymentError,
        } = getState().crud

        const {
            title,
            auction_category_id,
            prefix_id,
            lot_type,
            buyout_price,
            auction_amount,
            starting_bid,
            message,
            disadvantage
        } = auction;

        const {
            ak_no_pay,
            ak_min_price,
            ak_au_no_foto,
            auction_descript,
            ak_no_active_payment,
            auction_disadvantage,
            ak_au_ph_error_promotion,
            ak_au_error_set_lot_title,
            ak_au_phrase_quote_quantity,
            ak_au_error_set_image_primary,
            ak_au_ph_select_category_prefix,
            ak_au_ph_please_select_payment_method,
            ak_au_ph_please_select_shipping_method,
            please_enter_value_for_required_field_x,
        } = phrases

        let errorSubmitArr = [];

        const errorTitle = title.trim() === '';

        if (errorTitle) {
            errorSubmitArr = [...errorSubmitArr, {errorTitle, phrase: ak_au_error_set_lot_title}];
        }

        if (lot_type === 0) {
            const errorStartingBid = isNull(starting_bid) || parseInt(starting_bid) < dispatch(getStartPrice());

            if (errorStartingBid) {
                errorSubmitArr = [...errorSubmitArr, {
                    errorStartingBid,
                    phrase: ak_min_price.replace(/\{min_price\}/, dispatch(getStartPrice()))
                }];
            }
        }

        if (lot_type === 1) {
            const errorBuyoutPrice = isNull(buyout_price)
            if (errorBuyoutPrice) {
                errorSubmitArr = [...errorSubmitArr, {errorBuyoutPrice, phrase: ''}];
            }

            const errorQuantity = auction_amount < 2

            if (errorQuantity) {
                errorSubmitArr = [...errorSubmitArr, {errorQuantity, phrase: ak_au_phrase_quote_quantity}];
            }
        }

        let errorAttachments = attachments.length === 0;
        let phraseAttachmentsError = ak_au_no_foto;

        if (!errorAttachments) {
            const primaryImage = find(attachments, {'primary_image': 1});

            if (isUndefined(primaryImage)) {
                errorAttachments = true;
                phraseAttachmentsError = ak_au_error_set_image_primary;
            }
        }

        if (errorAttachments) {
            errorSubmitArr = [...errorSubmitArr, {errorAttachments, phrase: phraseAttachmentsError}];
        }

        let description = Helpers.removeTagsText(message);
        const errorMessage = description === ''

        if (errorMessage) {
            errorSubmitArr = [...errorSubmitArr, {
                errorMessage,
                phrase: please_enter_value_for_required_field_x.replace(/\{field\}/, auction_descript)
            }];
        }

        let defects = Helpers.removeTagsText(disadvantage);
        const errorDisadvantage = defects === ''

        if (errorDisadvantage) {
            errorSubmitArr = [...errorSubmitArr, {
                errorDisadvantage,
                phrase: please_enter_value_for_required_field_x.replace(/\{field\}/, auction_disadvantage)
            }];
        }

        const errorPromotion = promotionShow && !selectedPromotionType.change
        if (errorPromotion) {
            errorSubmitArr = [...errorSubmitArr, {errorPromotion, phrase: ak_au_ph_error_promotion}];
        }

        let errorDelivery = deliveryError
        let phraseErrorDelivery = ak_au_ph_please_select_shipping_method

        if (!errorDelivery) {
            // delivery_methods
            const result = filter(delivery_methods, function (o) {
                return o.delivery_error;
            });
            errorDelivery = result.length > 0;

            if (errorDelivery) {
                phraseErrorDelivery = ak_no_pay
            }
        }

        if (errorDelivery) {
            errorSubmitArr = [...errorSubmitArr, {errorDelivery, phrase: phraseErrorDelivery}];
        }

        if (paymentError) {

            const phraseErrorPayment = (payment_methods.length === 0) ? ak_no_active_payment : ak_au_ph_please_select_payment_method

            errorSubmitArr = [...errorSubmitArr, {paymentError, phrase: phraseErrorPayment}];
        }

        const errorPrefix = (includes(keys(prefixes), String(auction_category_id)) && prefix_id === 0)

        if (errorPrefix) {
            errorSubmitArr = [...errorSubmitArr, {errorPrefix, phrase: ak_au_ph_select_category_prefix}];
        }

        const errorSubmit = errorSubmitArr.length !== 0;

        dispatch({type: SET_ERROR_SUBMIT_FORM, payload: {errorSubmit, errorSubmitArr}});

        return errorSubmit

    }
}

export function changeErrorSubmit(errorSubmit) {
    return (dispatch) => {
        dispatch({type: SET_ERROR_SUBMIT_FORM, payload: {errorSubmit}});
    }
}

export function getStartPrice() {
    return (dispatch, getState) => {
        const {auction: {auction_category_id}, categorySettings, min_start_price} = getState().crud;

        const item = find(categorySettings, {'auction_category_id': auction_category_id});

        if (item !== undefined) {
            return item.min_start_price;
        }

        return min_start_price
    }
}

export function _getPromotionData() {
    return (dispatch, getState) => {
        const {auction: {auction_category_id, endDate}} = getState().crud;

        const unixTime = Math.floor(endDate.getTime() / 1000);
        const obj = {category_id: auction_category_id, 'end_date' : unixTime}
        return crudApiService.getPromotionData(obj).then((json) => {

            const promotionData = orderBy(json.promotionData, ['coast'], ['asc']);
            return {...json, promotionData};
        });
    }
}

export function getPromotionData() {
    return (dispatch) => {
        const data = dispatch(_getPromotionData());
        data.then((val) => {
            dispatch(setPromotionData(val));
        });

        return data;
    }
}

export function setPromotionData(data) {
    return (dispatch) => {
        dispatch({type: SET_PROMOTION_DATA, payload: data});
    }
}

export function createLot() {
    return (dispatch, getState) => {
        const error = dispatch(validationData());

        if (error) {
            return false;
        }

        const {
            auction: {
                title,
                auction_category_id,
                endDate,
                message,
                disadvantage,
                starting_bid,
                buyout_price,
                auto_reedit_lot,
                lot_type,
                auction_amount,
                allow_offer_price,
                only_positive_balance,
                only_not_ignore,
                only_over_one_month,
                auto_promotion_lot,
                prefix_id
            },
            payment_methods,
            delivery_methods,
            selectedPromotionType: {type: promotion_type},
            attachmentParams: {hash: attachment_hash},
            attachments,
            imageOrder
        } = getState().crud;


        const unixTime = Math.floor(endDate.getTime() / 1000);

        const primary = find(attachments, (o) => {
            return o.primary_image === 1
        })

        const obj = {
            category_id: auction_category_id,
            prefix_id: prefix_id,
            title: title,
            message_html: message,
            disadvantage_html: disadvantage,
            starting_bid: starting_bid,
            buyout_price: buyout_price,
            auto_reedit_lot: auto_reedit_lot,
            lot_type: lot_type,
            lot_quantity: auction_amount,
            allow_offer_price: allow_offer_price,
            only_positive_balance: only_positive_balance,
            only_not_ignore: only_not_ignore,
            auto_promotion_lot: auto_promotion_lot,
            only_over_one_month: only_over_one_month,
            primary_attachment_id: primary.attachment_id,
            attachment_hash: attachment_hash,
            end_date: unixTime
        };

        if (!isUndefined(promotion_type)) {
            obj.promotion_type = promotion_type;
        }

        const payments = filter(payment_methods, function (o) {
            return o.enabled;
        });

        const deliveries = filter(delivery_methods, function (o) {
            return o.enabled;
        });

        deliveries.forEach(({delivery_id, enabled, delivery_pred_pay, delivery_nal_pay}) => {
            obj[`deliveries[${delivery_id}][delivery_id]`] = delivery_id;
            obj[`deliveries[${delivery_id}][enabled]`] = enabled ? 1 : 0;
            obj[`deliveries[${delivery_id}][shipping_option][prepay]`] = delivery_pred_pay ? 1 : 0;
            obj[`deliveries[${delivery_id}][shipping_option][cash_on_delivery]`] = delivery_nal_pay ? 1 : 0;
        });

        payments.forEach(({payment_id, enabled}) => {
            obj[`payments[${payment_id}][payment_id]`] = payment_id;
            obj[`payments[${payment_id}][enabled]`] = enabled ? 1 : 0;
        });

        imageOrder.forEach(({attachment_id, order}) => {
            obj[`image_order[${attachment_id}][attachment_id]`] = attachment_id;
            obj[`image_order[${attachment_id}][order]`] = order;
        });

        obj['_xfConfirm'] = '1';

        return crudApiService.sendFormCreateLot(obj).then((json) => {

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            const {_redirectStatus, _redirectTarget} = json

            if (_redirectStatus === 'ok') {
                dispatch(deleteSessionStorageData());
                window.location.assign(_redirectTarget);
            }
        });
    }
}

export function saveLot() {
    return (dispatch, getState) => {
        const error = dispatch(validationData());

        if (error) {
            return false;
        }

        const {
            auction: {
                auction_id,
                title,
                auction_category_id,
                endDate,
                message,
                disadvantage,
                starting_bid,
                buyout_price,
                auto_reedit_lot,
                lot_type,
                auction_amount,
                allow_offer_price,
                only_positive_balance,
                only_not_ignore,
                only_over_one_month,
                prefix_id
            },
            visitor: {csrf_token_page},
            payment_methods,
            delivery_methods,
            imageOrder,
            attachmentParams: {hash: attachment_hash},
            attachments,
            reedit,
            create_copy
        } = getState().crud;

        const unixTime = Math.floor(endDate.getTime() / 1000);

        const primary = find(attachments, (o) => {
            return o.primary_image === 1
        })

        const obj = {
            auction_id: auction_id,
            category_id: auction_category_id,
            reedit: reedit,
            create_copy: create_copy,
            prefix_id: prefix_id,
            title: title,
            message_html: message,
            disadvantage_html: disadvantage,
            starting_bid: starting_bid,
            buyout_price: buyout_price,
            auto_reedit_lot: auto_reedit_lot,
            lot_type: lot_type,
            lot_quantity: auction_amount,
            allow_offer_price: allow_offer_price,
            only_positive_balance: only_positive_balance,
            only_not_ignore: only_not_ignore,
            only_over_one_month: only_over_one_month,
            primary_attachment_id: primary.attachment_id,
            attachment_hash: attachment_hash,
            end_date: unixTime
        };

        const deliveries = filter(delivery_methods, function (o) {
            return o.enabled;
        });

        deliveries.forEach(({delivery_id, enabled, delivery_pred_pay, delivery_nal_pay}) => {
            obj[`deliveries[${delivery_id}][delivery_id]`] = delivery_id;
            obj[`deliveries[${delivery_id}][enabled]`] = enabled ? 1 : 0;
            obj[`deliveries[${delivery_id}][shipping_option][prepay]`] = delivery_pred_pay ? 1 : 0;
            obj[`deliveries[${delivery_id}][shipping_option][cash_on_delivery]`] = delivery_nal_pay ? 1 : 0;
        });

        const payments = filter(payment_methods, function (o) {
            return o.enabled;
        });

        payments.forEach(({payment_id, enabled}) => {
            obj[`payments[${payment_id}][payment_id]`] = payment_id;
            obj[`payments[${payment_id}][enabled]`] = enabled ? 1 : 0;
        });

        imageOrder.forEach(({attachment_id, order}) => {
            obj[`image_order[${attachment_id}][attachment_id]`] = attachment_id;
            obj[`image_order[${attachment_id}][order]`] = order;
        });

        obj['_xfConfirm'] = '1';



        return crudApiService.sendFormEditLot(obj).then((json) => {
            const {_redirectStatus, _redirectTarget} = json

            if ('error' in json) {
                alert(json.error[0]);
                return
            }

            if (_redirectStatus === 'ok') {
                dispatch(deleteSessionStorageData());
                window.location.assign(_redirectTarget);
            }

        });
    }
}

export function setCrudTitle() {

    return (dispatch, getState) => {
        const {
            phrases: {create_auction, auctions, edit_auction}, categories, auction
        } = getState().crud;

        const {auction_category_id, auction_id} = auction;


        let title = create_auction

        let lotsBreadCrumbs = [
            {
                'href': '/',
                'value': auctions,
                action: () => {
                    dispatch(push('/'))
                }
            }
        ];

        if (auction_category_id !== 0) {

            const current_category = find(categories, {auction_category_id})

            let res = dispatch(allParentsByCategoryId(auction_category_id)).reverse();

            let catTitle = '';
            res.map((item, key) => {
                const {category_title} = item;
                const linkHref = `/auctions/${Helpers.getCategoryVariableReference(item)}`;
                let separator = (res.length - 1 === key) ? '' : ' - ';
                catTitle += category_title + separator

                lotsBreadCrumbs.push({
                    'href': linkHref,
                    'value': item.category_title,
                    action: () => {
                        dispatch(push(linkHref))
                    }
                });

                return true;
            });

            const linkHref = (isNull(auction_id))
                ? `/auction-process/create/${Helpers.getCategoryVariableReference(current_category)}`
                : `/auction-process/edit/id=${auction_id}`;

            title = (isNull(auction_id)) ? create_auction : edit_auction

            lotsBreadCrumbs.push({
                'href': linkHref,
                'value': title,
                action: () => {
                    dispatch(push(linkHref))
                }
            });
        }

        document.title = `${title} | REIBERT.info`;

        let xenTitle = document.querySelector('div.titleBar h1');
        let pageDescription = document.querySelector('#pageDescription');

        if (xenTitle !== null) {
            xenTitle.innerText = title;
        }

        if (pageDescription !== null) {
            pageDescription.innerText = '';
        }

        const breadcrumsTop = document.querySelector('.breadBoxTop nav fieldset.breadcrumb span.crumbs');
        const breadcrumsBottom = document.querySelector('.breadBoxBottom nav fieldset.breadcrumb span.crumbs');

        if (breadcrumsTop !== null && breadcrumsBottom !== null) {
            Helpers.makeBreadCrumb(breadcrumsTop, lotsBreadCrumbs)
            Helpers.makeBreadCrumb(breadcrumsBottom, lotsBreadCrumbs)
        }
    }
}

export function allParentsByCategoryId(category_id, result = []) {
    return (dispatch, getState) => {
        const {categories} = getState().crud;

        categories.map((item) => {
            if (item.auction_category_id === category_id) {
                result.push(item);

                if (item.parent_category_id !== 0) {
                    dispatch(allParentsByCategoryId(item.parent_category_id, result));
                }
            }
            return true
        });
        return result;
    }
}

export function getSortImages(attachments) {

    attachments = orderBy(attachments, ['attachment_id'], ['asc']);

    const imageOrders = JSON.parse(sessionStorage.getItem('imageOrder'));

    if (!isNull(imageOrders) && attachments.length > 1) {

        let defaultOrder = attachments.length + 1;

        attachments = attachments.map((val) => {
            const {attachment_id} = val
            const orderData = find(imageOrders, ['attachment_id', attachment_id]);

            val.order = (!isUndefined(orderData)) ? parseInt(orderData.order) : defaultOrder;

            if (val.order === defaultOrder) {
                defaultOrder += 1;
            }

            return val
        });

        attachments = orderBy(attachments, ['order'], ['asc']);
    }

    return attachments
}