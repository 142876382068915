import React, {useEffect, useState} from "react";
import {map} from "lodash/collection"
import {cloneDeep, isEmpty, isNil} from "lodash/lang";
import {useDispatch, useSelector} from "react-redux";
import Helpers from "../../../services/helpers";
import {
    setParamsPromotionLots,
    setPromotionData
} from "../../../redux/modals/modal-promotion-lots/actionPromotionLots";


const LotFormPrice = (props) => {
    const dispatch = useDispatch();
    const {
        ak_au_promotion_lot, ak_days_phrase, price, ak_au_lot_promotion_active_error
    } = useSelector(state => state.lots.phrases);

    const {maxPeriod} = useSelector(state => state.modalPromotion);
    const [showPrice, setShow] = useState(true);

    const [selectVal, setSelect] = useState('');
    const [oldSelectVal, setOldSelect] = useState('');
    const {title, price_lists, auction_id, error_promotion} = props;

    useEffect(() => {
        const deffaultValue = (maxPeriod) ? 'all_days' : (oldSelectVal.length === 0) ? 'one_day' : oldSelectVal
        setSelect(deffaultValue)
        setOldSelect(deffaultValue)
    }, [maxPeriod])


    useEffect(() => {
        let data = cloneDeep(price_lists[selectVal])
        if (!isNil(data)) {
            data.auction_id = auction_id
            data.type_promotion = selectVal
            dispatch(setPromotionData(data, showPrice))
        }
    }, [selectVal, showPrice])

    const clazz = (showPrice) ? 'form-group form-check' : 'form-group form-check d-none'

    const setSelectValue = (e) => {
        const val = e.target.value
        setOldSelect(val);
        setSelect(val);
        if (maxPeriod) {
            dispatch(setParamsPromotionLots({maxPeriod: false}))
        }
    }

    const setShowPrice = () => {
        setShow(!showPrice)
    }

    const getOption = () => {
        return map(price_lists, (item, key) => {
            const {cost, count_day, change} = item
            return (
                <option disabled={!change} key={key} value={key}>
                    {ak_au_promotion_lot} {count_day} {ak_days_phrase} ({price}: {Helpers.currencyFormat(cost)})
                </option>
            )
        })
    }

    const getSelectPrice = () => {

        if (isEmpty(price_lists)) {

            const phrase = ak_au_lot_promotion_active_error
                .replace(/\{date\}/, error_promotion.date)
                .replace(/\{price\}/, error_promotion.price)

            return (
                <div className={clazz}>
                    <div className="alert alert-success" role="alert" dangerouslySetInnerHTML={{__html: phrase}}/>
                </div>
            )
        }

        return (
            <div className={clazz}>
                <select value={selectVal} onChange={(e) => setSelectValue(e)
                } className="form-control col-12 col-lg-6 form-control-sm">
                    {getOption()}
                </select>
            </div>
        )
    }

    return (
        <div className="form-group">
            <div className="form-group form-check">
                <input className="form-check-input" onChange={() => setShowPrice()} type="checkbox"
                       checked={showPrice} value=""/>
                <label className="form-check-label">
                    {title}
                </label>
            </div>

            {getSelectPrice()}
        </div>


    )

}
export default LotFormPrice