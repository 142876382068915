import React from 'react'

const ModalPrefixErrorBlock = ({error}) => {
    return (
        <div className="form-group row justify-content-center">
            <div className="col-4">
                {error}
            </div>
        </div>
    )
}
export default ModalPrefixErrorBlock