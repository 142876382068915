import React, {useEffect} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {setLinkData} from "../../redux/lots/action";
import {setParamsPageNav} from "../../redux/page-nav/actionPageNav";

const usePageNavFn = () => {

    const dispatch = useDispatch();

    const {
        showFrom, showTo, step, firstPage, lastPage, activePage,
    } = useSelector(state => {
        return state.pageNav
    });

    // useEffect(() => {
    //     window.scrollTo({top: 270, behavior: 'smooth'});
    // }, [activePage])

    const {phrases} = useSelector(state => {
        return state.lots
    });

    const onClickNextPages = () => {
        const to = showTo + step;
        const from = showFrom + step;

        dispatch(setParamsPageNav({
            showFrom: from,
            showTo: (to > lastPage) ? lastPage : to
        }));
    };

    const onClickPrevPages = () => {
        const to = showTo - step;
        const from = showFrom - step;

        dispatch(setParamsPageNav({
            showFrom: (from <= 1) ? firstPage : from,
            showTo: to
        }));
    };

    return {onClickPrevPages, onClickNextPages, phrases, showFrom, showTo, step, firstPage, lastPage, activePage}

}
export default usePageNavFn