import React, {useContext} from 'react'
import {map} from "lodash/collection";
import {ModalPrefixContext} from "./modal-prefix";

const ModalPrefixOptionItem = ({prefixes}) => {
    const {
        phrases,
    } = useContext(ModalPrefixContext)

    const objNoPrefix = {0: {prefix_id: 0}}
    const obj = {...objNoPrefix, ...prefixes}

    return map(obj, (value, key) => {


        return (
            <option key={key} value={value.prefix_id}>
                {phrases[`category_prefix_${value.prefix_id}`]}
            </option>
        )
    })
}
export default ModalPrefixOptionItem
