import {find, groupBy, includes, filter} from "lodash/collection";
import {isEmpty, toInteger, cloneDeep} from "lodash/lang";
import {keys} from "lodash/object"
import {SET_CATEGORIES, SET_SHOW_BLOCK_CATEGORIES} from "../../types";
import Helpers from "../../../services/helpers";
import {getAllParentsByCategoryId, getQueryLinkParams} from "../../lots/action";
import {push} from "connected-react-router";

export function setCategoriesData() {
    return (dispatch, getState) => {

        const {all_categories} = getState().lots
        const {auction_category_id: current_category_id} = dispatch(getQueryLinkParams());
        const allCategories = cloneDeep(all_categories);

        if (isEmpty(allCategories)) return false
        let openBlock = []

        const getOpenBlock = (category_id) => {
            const {parent_category_id, auction_category_id} = find(allCategories, {auction_category_id: category_id});
            if (auction_category_id !== current_category_id) {
                openBlock = [...openBlock, auction_category_id];
            }
            if (parent_category_id !== 0) {
                getOpenBlock(parent_category_id)
            }
        }

        if (current_category_id !== 0) {
            getOpenBlock(current_category_id);
        }

        let categories = groupBy(allCategories, 'parent_category_id');
        const categoriesKey = keys(categories);

        const getLotsCountInCategories = (index) => {
            categories[index].map((category, key) => {

                if (category.auction_category_id !== 56) {
                    let childrens = filter(allCategories, (o) => {
                        if (o.lft > category.lft && o.rgt < category.rgt) return o
                    });

                    let count = category.lots_count;

                    childrens.map(({lots_count}) => {
                        count += lots_count;
                        return true;
                    })

                    categories[index][key].lots_count = count
                }
                return true;
            })
        }


        categoriesKey.map((item) => {
            categories[toInteger(item)].show = includes(openBlock, toInteger(item));
            if (toInteger(item) === 0) {
                categories[toInteger(item)].show = true
            }
            getLotsCountInCategories(toInteger(item));
            return true
        });

        dispatch(setCategories(categories));
    }
}

export function setCategories(categories = {}) {
    return dispatch => {
        dispatch({type: SET_CATEGORIES, payload: categories});
    }
}

export function openBlockCategories(id) {
    return (dispatch, getState) => {

        const {categories} = getState().categoriesSidebar

        categories[id].show = !categories[id].show
        dispatch(setCategories(categories));
    }
}

export function showBlockCategories() {
    return (dispatch, getState) => {
        const {show} = getState().categoriesSidebar
        dispatch({type: SET_SHOW_BLOCK_CATEGORIES, payload: !show})
    }
}

export function setXenAuctionTitle() {

    return (dispatch, getState) => {
        const {
            lots: {xenOptions: {auction_description}, phrases: {ak_au_auction_title, auctions}, all_categories},
        } = getState()

        const {auction_category_id} = dispatch(getQueryLinkParams());

        let title = ak_au_auction_title
        let secondTitle = ak_au_auction_title
        let description = auction_description

        let lotsBreadCrumbs = [
            {
                'href': '/',
                'value': auctions,
                action: () => {
                    dispatch(push('/'))
                }
            }
        ];

        if (auction_category_id !== 0) {
            const {category_description, category_title, second_title} = find(all_categories, {auction_category_id})

            title = category_title;
            secondTitle = (isEmpty(second_title)) ? category_title : second_title;
            description = category_description;

            let res = dispatch(getAllParentsByCategoryId(auction_category_id)).reverse();

            let catTitle = '';
            res.map((item, key) => {
                const {category_title} = item;
                const linkHref = `/auctions/${Helpers.getCategoryVariableReference(item)}`;
                let separator = (res.length - 1 === key) ? '' : ' - ';
                catTitle += category_title + separator


                lotsBreadCrumbs.push({
                    'href': linkHref,
                    'value': item.category_title,
                    action: () => {
                        dispatch(push(linkHref))
                    }
                });

                return true;
            });
        }
        document.title = `${title} | REIBERT.info`;

        let xenTitle = document.querySelector('div.titleBar h1');
        let pageDescription = document.querySelector('#pageDescription');

        if (xenTitle !== null) {
            xenTitle.innerText = secondTitle;
        }

        if (pageDescription !== null) {
            pageDescription.innerText = description;
        }

        const breadcrumsTop = document.querySelector('.breadBoxTop nav fieldset.breadcrumb span.crumbs');
        const breadcrumsBottom = document.querySelector('.breadBoxBottom nav fieldset.breadcrumb span.crumbs');

        if (breadcrumsTop !== null && breadcrumsBottom !== null) {
            Helpers.makeBreadCrumb(breadcrumsTop, lotsBreadCrumbs)
            Helpers.makeBreadCrumb(breadcrumsBottom, lotsBreadCrumbs)
        }
    }
}

