import React from "react";

import ModalContentItem from "./modal-content-item";
import Helpers from "../../../services/helpers";

const ModalContent = (props) => {

    const {currencyFormat, percentFormat} = Helpers;

    const {
        option_promotion_price,
        ak_au_ph_cost_placing_bet,
        permission_auctions_min_start_price,
        ak_au_pay_for_reedit_lot,
        option_ak_au_min_percent_explain,
        option_ak_au_max_percent_explain,
        ak_au_your_percentage,
        ak_au_payment_creating_copy
    } =props.phrases;

    const {
        promotion_price,
        cost_placing_bet,
        min_start_price,
        pay_for_reedit_lot,
        min_percent,
        max_percent,
        percent_sale,
        payment_creating_copy
    } = props;

    const arr = [
        {name: ak_au_your_percentage, value: percentFormat(percent_sale)},
        {name: option_ak_au_min_percent_explain, value: currencyFormat(min_percent)},
        {name: option_ak_au_max_percent_explain, value: currencyFormat(max_percent)},
        {name: ak_au_ph_cost_placing_bet, value: currencyFormat(cost_placing_bet)},
        {name: option_promotion_price, value: currencyFormat(promotion_price)},
        {name: ak_au_pay_for_reedit_lot, value: currencyFormat(pay_for_reedit_lot)},
        {name: ak_au_payment_creating_copy, value: currencyFormat(payment_creating_copy)},
        {name: permission_auctions_min_start_price, value: currencyFormat(min_start_price)},
    ];

    const getItems = () => {
        return arr.map((item, key)=>{
            return <ModalContentItem key={key} {...item}/>
        })
    }

    return (
        <div className='row justify-content-center'>
            <div className="col-12 col-md-10 col-lg-8">
                <ul className="list-group">
                    {getItems()}
                </ul>
            </div>
        </div>
    );

}
export default ModalContent