import React, {useState} from 'react'
import {useDispatch, useSelector} from "react-redux";
import {sendFormPrefix, setParamsModalPrefix} from "../../../redux/modals/modal-prefix/actionModalPrefix";

const useModalPrefixFn = () => {

    const dispatch = useDispatch();

    const {
        phrases
    } = useSelector(state => {
        return state.lots
    })


    const {modalShow, prefixes, selectedPrefix, error, auctionIds} = useSelector(state => {
        return state.modalPrefix
    });

    const [chosenPrefix, setChosenPrefix] = useState(selectedPrefix)

    const sendForm = () => {
        dispatch(sendFormPrefix(chosenPrefix))
    }

    const handleShow = () => {
        dispatch(setParamsModalPrefix({modalShow: !modalShow}))
    }

    return {phrases, modalShow, prefixes, selectedPrefix, error, auctionIds, setChosenPrefix, sendForm, handleShow}

}
export default useModalPrefixFn