import React, {useContext, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getFormattedDate} from "../../../redux/lots/action";
import {LotsContext} from "../../auction-lots-listing/auction-lots-listing ";
import Helpers from '../../../services/helpers';
import './user-create-date-block.css';
import {lotContext} from "../../auction-lots-listing/components/lots-list-item/lots-list-item";

const UserCreateDateBlock = () => {

    const dispatch = useDispatch();

    const {visitor: {is_moderator, is_admin, user_id: visitor_user_id}, userAuction} = useSelector(state => state.lots);
    const {lotData} = useContext(lotContext);
    const {username, user_id, placement_date, hide_lot} = lotData;
    const {user_lots_type} = useContext(LotsContext);

    const {dateCreate, userLink} = useMemo(() => {

        let obj_link = {username, user_id}

        if (user_lots_type === 'archive_lots' && hide_lot) {
            if (visitor_user_id !== user_id && (!is_admin || !is_moderator)) {
                obj_link = {username: userAuction.username, user_id: userAuction.user_id}
            }
        }

        const dateCreate = dispatch(getFormattedDate(placement_date))
        const userLink = Helpers.getUserLink(obj_link);

        return {dateCreate, userLink};

    }, [hide_lot, user_lots_type, visitor_user_id, user_id, is_admin, is_moderator])

    return (
        <div className="d-flex muted mt-1 user-date-block">
            {userLink}<span className="text-muted">, {dateCreate}</span>
        </div>
    );
};

export default UserCreateDateBlock;