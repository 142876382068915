import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getLinkData, getQueryLinkParams, setLinkData} from "../../redux/lots/action";
import {getArchiveSwichData} from "../../redux/archive/actionArchive";
import {find} from "lodash/collection";
import {push} from "connected-react-router";
import * as queryString from "query-string";
import Helpers from "../../services/helpers";

const YearsNavPanel = () => {

    const {loading, years, all_categories} = useSelector((state) => {
        return state.lots;
    });

    const dispatch = useDispatch();

    const {
        type,
        user_lots_type,
        year: year_change,
        month: month_change,
        auction_category_id
    } = dispatch(getQueryLinkParams());

    const createButton = () => {
        return years.map(({year}) => {

            const clazz = (parseInt(year) === year_change)
                ? 'm-1 btn btn-danger btn-sm' : 'm-1 btn btn-info btn-rg btn-sm';

            const getLink = () => {

                const {link} = find(dispatch(getArchiveSwichData(year)), {type});

                if (auction_category_id !== 0) {
                    const category = find(all_categories, {auction_category_id});
                    return `${link}${Helpers.getCategoryVariableReference(category)}`
                }

                return link
            }

            const queryParams = queryString.stringify(dispatch(getLinkData({page: 1})));
            const link = `${getLink()}?${queryParams}`;

            return (
                <button key={year} type="button"
                        onClick={() => dispatch(push(link))}
                        className={clazz}>{year}
                </button>
            )
        });
    };

    const createButtonMonth = () => {

        const {months} = find(years, {'year': String(year_change)});

        const setLink = (data) => {
            dispatch(setLinkData({...data, page: 1}))
        }

        return Object.keys(months).map((key) => {

            const {monthNameUserFormat, monthName} = months[key];

            const clazz = (monthName === month_change)
                ? 'm-1 btn btn-danger btn-sm' : 'm-1 btn btn-info btn-rg btn-sm';

            return (
                <button key={monthName} type="button"
                        onClick={() => setLink({month: monthName})}
                        className={clazz}>{monthNameUserFormat}
                </button>
            )
        });
    }

    if (!loading && user_lots_type === 'archive_lots') {
        return (
            <div className='row'>
                <div className="col-12">
                    {createButton()}
                </div>
                <div className="col-12">
                    {createButtonMonth()}
                </div>
            </div>
        );
    }

    return null;

}

export default YearsNavPanel