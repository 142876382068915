import React from "react";
import {every} from "lodash/collection";
import {useDispatch, useSelector} from "react-redux";
import {onChangeRemoveAction} from "../../../../../redux/modals/modal-inline-mod-check/actionModal";
import RemoveBlockContent from "./remove-block-content";

const RemoveBlock = () => {

    const dispatch = useDispatch();

    const {
        changeLots
    } = useSelector((state) => {
        return state.lots
    });

    const handler_type = useSelector((state) => {
        return state.modal.handler_type
    });

    if (handler_type !== 3) {
        return null
    }

    const actionRemove = (type, textReasons) => {
        dispatch(onChangeRemoveAction({delete_type: type, delete_reason: textReasons}))
    }

    const showBlock = every(changeLots, {'status': 'active'});

    return <RemoveBlockContent showBlock={showBlock} actionRemove={actionRemove}/>
}

export default RemoveBlock