import React, {createContext} from "react";
import {isNil} from "lodash/lang";
import WithSuspense from "../../repeating-components/wrapper-components/with-suspense";
import useModalPrefixFn from "./useModalPrefixFn";
import ModalPrefixContent from "./modal-prefix-content";
const ModalBlackBacground = React.lazy(() => import('../../repeating-components/wrapper-components/modal-black-background'));

export const ModalPrefixContext = createContext();
const ModalPrefix = () => {

    const {phrases, modalShow, prefixes, selectedPrefix, error, auctionIds, setChosenPrefix, sendForm, handleShow} = useModalPrefixFn()

    const {
        ak_au_apply_category_prefix, ak_au_inline_moderation_category_prefix
    } = phrases

    if (modalShow) {
        return (
            <WithSuspense>
                <ModalBlackBacground
                    setShow={handleShow}
                    show={modalShow}
                    sendForm={sendForm}
                    phraseTitle={ak_au_inline_moderation_category_prefix}
                    phraseSubmit={ak_au_apply_category_prefix}
                    error={!isNil(error)}
                >
                    <ModalPrefixContext.Provider value={{error, auctionIds, prefixes, selectedPrefix, phrases, setChosenPrefix}}>
                        <ModalPrefixContent/>
                    </ModalPrefixContext.Provider>
                </ModalBlackBacground>
            </WithSuspense>
        )
    }

    return null

}
export default ModalPrefix