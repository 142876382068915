import React from 'react';

const SelectComponent = (props) => {

    const {
        setAction,
        value,
        name,
        dataOptions,
        clazz='border-rg text-white bg-rgreen custom-select custom-select-sm'
    } = props;

    const getOptions = () => {
        return dataOptions.map((option, index) => {
            const {name, value} = option;
            return (
                <option key={index} value={value}>
                    {name}
                </option>
            )
        });
    }

    return (
        <select className={clazz}
                value={value}
                onChange={(e) => setAction(e.target.value)}
                name={name}>
            {getOptions()}
        </select>
    );

}
export default SelectComponent;