import React, {useCallback, useContext, useEffect, useState} from 'react'
import {useDispatch} from "react-redux";
import {LotsContext} from "../../auction-lots-listing ";
import {find, some} from "lodash/collection";
import {isObject} from "lodash/lang";
import {reverse} from "lodash/array";
import Helpers from "../../../../services/helpers";

const useLotListItemFn = (props) => {

    const dispatch = useDispatch();

    const {lotData} = props

    const {
        auction_id,
        auction_category_id,
        title,
        primary_image,
        color_background
    } = lotData

    const {
        auction_categories,
        onToggleLots,
        showImage,
        promotion_color,
        changeLots
    } = useContext(LotsContext);


    const [onSelected, setSelected] = useState(false)
    const [lotCategories, setLotCategories] = useState([])


    useEffect(() => {
        createLotCategories(auction_category_id, []);
    }, [auction_category_id])

    useEffect(()=>{
        const selected = some(changeLots, {'auction_id': auction_id})
        setSelected(selected)
    }, [changeLots])

    const createLotCategories = useCallback(function (category_id, categories){
        const category = find(auction_categories, {auction_category_id: category_id});

        if (isObject(category)) {
            let newArr = [
                ...categories,
                category
            ];

            if (parseInt(category.parent_category_id) !== 0) {
                createLotCategories(category.parent_category_id, newArr)
            }else {
                setLotCategories(reverse(newArr))
            }
        }
    },[])


    const onToggleLot = useCallback(() => {
        const change = !onSelected;
        onToggleLots(auction_id, change);
    }, [dispatch, onSelected]);

    const setShow = () => {
        showImage(primary_image)
    }

    const promotion = {
        backgroundColor: (color_background === 1) ? promotion_color : null
    }

    let mainClazz = `container-fluid rounded shop-list-wrap shop-list-mrg2 shop-list-mrg-none mb-15 ${onSelected ? 'on-selected-lot' : ''}`;


    return {
        mainClazz,
        onSelected,
        lotData,
        promotion,
        lotThumbnail: {
            title,
            imgUrl: Helpers.setImg(primary_image, true),
            setShow
        },
        lotHeaderInfo: {
            onSelected, onToggleLot, categories: lotCategories
        }
    }

}

export default useLotListItemFn