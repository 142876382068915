import {
    CHANGE_ALL_LOTS,
    DELETE_CHANGE_LOT,
    GET_DFAULT_LOTS_LIST_PARAMS,
    ON_TOGGLE_ALL_LOTS, SET_ALL_CATEGORIES,
    SET_CHANGE_LOT,
    UPDATE_LOTS_DATA,
} from '../types';

import ReibertApiService from '../../services/reibert-api-service';
import Helpers from "../../services/helpers";
import {filter, find, groupBy, includes, orderBy} from "lodash/collection";
import {findIndex} from "lodash/array";
import {isEmpty, isNull, isUndefined} from "lodash/lang";
import * as queryString from 'query-string';
import {setXenAuctionTitle} from "../sidebars/categories-sidebar/actionCategoriesSidebar";
import {setOnlineModerator} from "../sidebars/sidebar-auction-moderators/actionAuctionModeratorsSideBar";
import {setOnlineUsers} from "../sidebars/sidebar-online-users/actionOnlineUsersSideBar";
import {setDataStats} from "../sidebars/sidebar-stats/actionSideBarStats";
import {installFilters, removeFilter} from "../sidebars/filter-sidebar/actionFilterSideBar";
import {push, createMatchSelector} from "connected-react-router";
import {split} from "lodash/string";
import {setXenTitle} from "../sidebars/user-sidebar/actionUserSideBar";
import {getArchiveSwichData} from "../archive/actionArchive";


import moment from "moment/moment";
import momentTimezone from 'moment-timezone'
import 'moment/locale/ru';
import 'moment/locale/uk';
import {setPageNavData} from "../page-nav/actionPageNav";
import ProcessApiService from "../../services/process-api-service";

const reibertApiService = new ReibertApiService();

export function getQueryLinkParams() {

    return (dispatch, getState) => {

        const state = getState()

        const {router: {location}, lots: {user_sort_data}} = state

        const getMatch = () => {
            const pathArr = [
                {path: '/auction/:user_id/:type?'},
                {path: "/auctions/:auction_category_id"},
                {path: "/lots/:auction_id"},
                {path: "/auction-history/:action"},
                {path: "/archive/:year/:type/:auction_category_id?/"},
            ]

            let matchParams;

            pathArr.map((path) => {
                let match = createMatchSelector(path)(state);
                if (!isNull(match)) {
                    matchParams = match.params;
                    return true;
                }
            });

            return matchParams;
        }

        const getMatchParams = (params) => {

            const matchParams = getMatch();

            let value = undefined

            if (!isUndefined(matchParams)) {
                value = !isUndefined(matchParams[params]) ? matchParams[params] : undefined;

                if (matchParams.hasOwnProperty(params)) {
                    const getParsXenParams = (item) => {
                        const arr = split(item, '.');
                        return arr[arr.length - 1];
                    }

                    switch (params) {
                        case 'user_id':
                        case 'auction_category_id':
                            return (isUndefined(value)) ? 0 : parseInt(getParsXenParams(value))
                        case 'auction_id':
                            return (isUndefined(value)) ? 0 : parseInt(getParsXenParams(value))
                        case 'type':
                            return (isUndefined(value)) ? 'for_sales' : value
                        case 'year':
                            return parseInt(value)
                        default:
                            return value;
                    }
                }
            }

            return (params === 'auction_category_id') ? 0 : value;
        }


        const {
            page = 1,
            order,
            direction,
            prefix_id,
            disput_id,
            type = getMatchParams('type'),
            user_lots_type,
            auction_category_id = getMatchParams('auction_category_id'),
            auction_id = getMatchParams('auction_id'),
            year = getMatchParams('year'),
            month = getMatchParams('month'),
            search = '',
            user_id = getMatchParams('user_id'),
            lotType = 'all'
        } = location.query


        let arr = {
            year: parseInt(year),
            month: month,
            page: parseInt(page),
            auction_category_id: parseInt(auction_category_id),
            search: decodeURI(search),
            order,
            direction,
            type,
            user_lots_type
        }

        const getType = () => {
            switch (user_lots_type) {
                case 'auction_lots':
                default :
                    return 'for_sales'
            }
        }

        if (isEmpty(type)) {
            arr.type = getType();
        }

        const getUserLotsType = (type) => {

            switch (type) {
                case 'for_sales':
                    return (isUndefined(user_id)) ? 'auction_lots' :
                        (location.pathname !== "/auction-history/user-bidding") ? 'user_lots' : 'user_bidding'
                case 'sale':
                    if (location.pathname === "/auction-history/purchased-user-lot") {
                        return 'purchases'
                    }
                    return ((!isUndefined(user_id))) ? 'user_lots' :
                        (isUndefined(year)) ? 'user_lots' : 'archive_lots'
                case 'finalized':
                    return ((!isUndefined(user_id))) ? 'user_lots' :
                        (isUndefined(year)) ? 'user_lots' : 'archive_lots'
                case 'sold':
                    arr.type = 'sale';
                    return (isUndefined(year)) ? 'user_lots' : 'archive_lots';
                case 'wait':
                    arr.type = 'unfinished';
                    return 'archive_lots'
                case 'no-sold':
                    arr.type = 'finalized';
                    return 'archive_lots'

                case 'no_sale':
                case 'all_user_bets':
                    return 'user_bidding'
                case 'vacation':
                case 'inactive_copy':
                case 'watch_lots':
                case 'nosale':
                case 'no_pay':
                case 'awaiting_shipment':
                case 'awaiting_confirmation':
                case 'awaiting_payment':
                    return 'user_lots'
                case 'send':
                case 'take':
                case 'waitforpay':
                    return 'purchases'
                default:
                    return 'auction_lots'
            }

        }

        if (isUndefined(user_lots_type)) {
            arr.user_lots_type = getUserLotsType(arr.type)
        }

        const getDefaultOrder = () => {
            const {user_lots_type, type} = arr;

            if (isUndefined(user_sort_data)) {
                return 'time'
            }

            const {sort_order} = find(user_sort_data, {user_lots_type, type});

            return sort_order;
        }

        if (isUndefined(order)) {
            arr.order = getDefaultOrder()
        }

        const date = new Date();
        const nowYear = date.getFullYear()

        if (arr.user_lots_type === 'archive_lots' && isUndefined(year)) {
            arr.year = nowYear
        }

        if (arr.user_lots_type === 'archive_lots' && !isUndefined(prefix_id)) {
            arr.prefix_id = prefix_id;
        }

        if (arr.user_lots_type === 'user_lots' && type === 'for_sales') {
            arr.lotType = lotType;
        }

        if (arr.user_lots_type === 'archive_lots' && isUndefined(month)) {

            let month = 'January';

            if (arr.year === nowYear) {
                const options = {month: 'long'};
                month = date.toLocaleString('en-US', options);
            }

            arr.month = month
        }

        if (!isUndefined(user_id)) {
            arr.user_id = parseInt(user_id)
        }

        if (isUndefined(user_lots_type)) {
            if (!isUndefined(auction_id)) {
                arr.auction_id = parseInt(auction_id);
            }
            if (!isUndefined(disput_id)) {
                arr.disput_id = parseInt(disput_id);
            }
        }

        const getDirection = () => {
            const {user_lots_type, type} = arr;

            if (isUndefined(user_sort_data)) {
                return 'asc'
            }

            const {direction} = find(user_sort_data, {user_lots_type, type});
            return direction
        }

        if (isUndefined(direction)) {
            arr.direction = getDirection()
        }

        return arr
    }
}

export function getParams(urlCreation = false) {
    return (dispatch, getState) => {

        const state = getState()

        const {
            all_categories, visitor: {categories_for_filter, filter_category_value}
        } = state.lots;

        const {
            page, order, direction, type, user_lots_type, auction_category_id, year, month, search, user_id, prefix_id, lotType
        } = dispatch(getQueryLinkParams());

        let obj = {order, direction, type, page, user_lots_type};

        if (user_lots_type === 'archive_lots') {
            obj.year = year
            obj.month = month
            obj.prefix_id = prefix_id
        }

        if (user_lots_type === 'user_lots' && type === 'for_sales' && lotType !== 'all') {
            obj.lotType = lotType
        }

        if (!user_lots_type.includes(['archive_lots', 'auction_lots'])) {
            obj.user_id = user_id
        }

        if (auction_category_id !== 0) {
            obj.auction_category_id = auction_category_id;
        }

        if (search !== undefined && search.length > 0) {
            obj.search = search;
        }

        if (user_lots_type === 'auction_lots') {

            const {filterInput} = state.filterSidebar;

            const filters = filter(filterInput, {'initial': false})

            filters.map(({name, value}) => {
                if (name === 'filter_category' && auction_category_id === 0) {
                    if (urlCreation) {
                        obj[name] = (value === 'ind')
                            ? (filter_category_value === 'ind') ? categories_for_filter : filter_category_value
                            : value
                    } else {
                        obj[name] = (isEmpty(all_categories))
                            ? value
                            : getIdsFilterCategories(state, value);
                    }
                } else if (name === 'filter_subcategories' && auction_category_id !== 0) {
                    obj[name] = value;
                } else if (!name.includes(['filter_category', 'filter_subcategories'])) {
                    obj[name] = value;
                }
                return true
            })
        }

        return obj;
    }

}

function getIdsFilterCategories(state, filterCategories) {
    const {
        all_categories,
        visitor: {categories_for_filter, filter_category_value},
    } = state.lots;


    let cat = (filterCategories === 'ind')
        ? (filter_category_value === 'ind') ? categories_for_filter : filter_category_value
        : filterCategories

    if (cat === 'all') {
        return JSON.stringify(cat)
    }

    let catIds = [];
    let catArr = (filterCategories === 'ind') ? cat.split(',') : cat.split('-');

    const auction_categories = groupBy(all_categories, 'parent_category_id');

    catArr.map(i => {
        let val = parseInt(i)
        let change_category = auction_categories[val];
        catIds.unshift(val);

        if (change_category !== undefined) {

            change_category.map((item) => {
                catIds.unshift(item.auction_category_id);

                if (auction_categories[item.auction_category_id] !== undefined) {
                    auction_categories[item.auction_category_id].map((val) => {
                        catIds.unshift(val.auction_category_id);
                        return true
                    });
                }
                return true
            });
        }
        return true
    })


    return JSON.stringify(catIds)
}

// ������� ���������� json � ��������� ����������
export function getIdCategories() {
    return (dispatch, getState) => {
        const state = getState();
        const {all_categories} = state.lots;
        const {auction_category_id} = dispatch(getQueryLinkParams());
        let catIds = [];

        const auction_categories = groupBy(all_categories, 'parent_category_id');

        let change_category = auction_categories[auction_category_id];

        catIds.unshift(auction_category_id);

        if (change_category !== undefined) {

            change_category.map((item) => {
                catIds.unshift(item.auction_category_id);

                if (auction_categories[item.auction_category_id] !== undefined) {
                    auction_categories[item.auction_category_id].map((val) => {
                        catIds.unshift(val.auction_category_id);
                        return true
                    });
                }
                return true
            });
        }

        return JSON.stringify(catIds)
    }
}

export function getGlobalParams() {
    return (dispatch, getState) => {

        const {startLoading} = getState().lots;

        if (startLoading) {

            const {user_lots_type} = dispatch(getQueryLinkParams())

            let obj = {user_lots_type}

            return reibertApiService.getLotsListParams(obj).then(json => {

                const {
                    phrases, css_classes_prefix, ignore_view, rating_values, time_wait_send, time_wait_pay, imagePath, user_sort_data,
                    time_wait_take, years = [], xenOptions, all_categories, prefixes_in_categories, visitor, languages, csrfToken
                } = json;

                const showImgLotList = localStorage.getItem('showImgLotList');

                const payload = {
                    phrases,
                    visitor,
                    csrfToken,
                    imagePath,
                    xenOptions,
                    ignore_view,
                    rating_values,
                    user_sort_data,
                    css_classes_prefix,
                    startLoading: false,
                    prefixes_in_categories,
                    time_wait_pay: parseInt(time_wait_pay),
                    time_wait_send: parseInt(time_wait_send),
                    time_wait_take: parseInt(time_wait_take),
                    years: orderBy(years, ['year'], ['desc']),
                    languages: orderBy(languages, ['language_id'], ['asc']),
                    all_categories: orderBy(all_categories, ['lft'], ['asc']),
                    showImgLotList: !isNull(showImgLotList) ? parseInt(showImgLotList) : 1
                };

                dispatch({type: GET_DFAULT_LOTS_LIST_PARAMS, payload});
                dispatch(_setSideBarParams(json));

                return json;
            });
        }
    }
}

// ����� �������� ��������� ��������
export function getDefaultLotListParams() {
    return async (dispatch) => {

        dispatch(getGlobalParams()).then(json => {
            // dispatch(_setSideBarParams(json));
        }).then(() => {
            dispatch(updateLotsData());
        });
    }
}

export function updateLotsData() {

    return async (dispatch, getState) => {
        dispatch({type: UPDATE_LOTS_DATA, payload: {loading: true}});
        dispatch(clearChangeLot())

        const state = getState();
        let obj = dispatch(getParams());

        const {order, direction, user_lots_type, auction_category_id, filter_subcategories = 0} = obj

        if (!filter_subcategories) {
            const types = ['archive_lots', 'auction_lots'];
            if (types.includes(user_lots_type) && auction_category_id) {
                obj.auction_category_id = dispatch(getIdCategories());
            }
        }

        reibertApiService.getUserLots(obj)
            .then((json) => {

                if ('error' in json) {
                    alert(json.error[0])
                }

                let sortingData = Helpers.getSortingData({...state.lots, ...json, ...obj});

                const {
                    user_owner_data, visitor, csrfToken, perPage, total, owner_lots_categories, topBiddersDeliveryMethod,
                    users_payment_methods, promotionLots = {}, auctions = {}, userAuction = {},
                    totalMultiLots = 0, totalSimpleLots = 0, totalAllLots = 0
                } = json

                const {sortOrder} = find(sortingData, {order});
                const sortOrderArrCheck = ['top_bidder', 'title', 'users'];

                let lotsData = orderBy(
                    auctions,
                    (sortOrderArrCheck.includes(order))
                        ? [auction => auction[sortOrder].toLowerCase()] : sortOrder,
                    direction
                );

                if (!isEmpty(promotionLots)) {
                    const {promotion_lot_output} = (isEmpty(state.lots.xenOptions)) ? json.xenOptions : state.lots.xenOptions;

                    const lotsPromotion = orderBy(
                        promotionLots,
                        (sortOrderArrCheck.includes(order))
                            ? [promotionLot => promotionLot[sortOrder].toLowerCase()] : sortOrder,
                        direction);

                    let output_promotion_lot = parseInt(promotion_lot_output);

                    let newLotsData = [];

                    if (!isEmpty(lotsData) || lotsData.length !== 1) {
                        let key = 0;

                        for (let i = 0; i !== lotsData.length; i++) {
                            if (i === output_promotion_lot) {
                                if (!isEmpty(lotsPromotion[key])) {
                                    lotsPromotion[key].color_background = 1
                                    newLotsData = [...newLotsData, lotsPromotion[key]]
                                    output_promotion_lot += parseInt(promotion_lot_output)
                                    ++key;
                                }
                            }

                            if (!isEmpty(lotsData[i])) {
                                newLotsData = [...newLotsData, lotsData[i]]
                            }

                        }
                        lotsData = newLotsData;
                    }
                }

                const payload = {
                    total,
                    visitor,
                    csrfToken,
                    perPage,
                    lotsData,
                    promotionLots,
                    totalMultiLots,
                    totalSimpleLots,
                    totalAllLots,
                    user_owner_data,
                    users_payment_methods,
                    owner_lots_categories,
                    topBiddersDeliveryMethod,
                    userAuction,
                    loading: false,
                    simulateLoading: false
                };

                dispatch({type: UPDATE_LOTS_DATA, payload});
                dispatch(setPageNavData())
                dispatch(setAuctionLinks())
                dispatch(setCountersConversationsAndAlerts())
            }).then(() => {

            if (user_lots_type === 'auction_lots') {
                dispatch(setXenAuctionTitle())
            }

            if (!includes(['auction_lots', 'archive_lots'], user_lots_type)) {
                dispatch(setXenTitle());
            }
        });
    }
}

export function setLotsData() {
    return (dispatch, getState) => {
        const {startLoading} = getState().lots;

        if (startLoading === true) {
            dispatch(getDefaultLotListParams());
        } else {
            dispatch(updateLotsData());
        }
    }
}

export function getLinkData(data) {
    return (dispatch, getState) => {

        const {prefixes_in_categories} = getState().lots

        const {search = '', user_lots_type} = data
        const query = dispatch(getQueryLinkParams());

        const {auction_category_id} = query

        if (query.search.length > 0 && (user_lots_type !== undefined && user_lots_type !== query.user_lots_type)) {
            query.search = search
        }

        if (!isUndefined(data.auction_category_id)) {
            dispatch(removeFilter('prefix_id'))
        }

        const obj = {...query, ...data}

        // if ((!includes(['for_sales'], obj.type) && obj.user_lots_type !== 'user_lots') || obj.lotType === 'all') {
        //     delete obj.lotType
        // }

        if (obj.user_lots_type === 'user_lots') {
            if (includes(['sale', 'for_sales'], obj.type)) {
                delete obj.user_id
            }

            // if (includes(['for_sales'], obj.type)) {
            //     if (obj.lotType !== 'multi' && obj.order === 'lot_quantity') {
            //         delete obj.order
            //     }
            // }
        }

        if (obj.user_lots_type === 'archive_lots' && !isEmpty(obj.prefix_id)) {
            delete obj.prefix_id;
        }

        if (obj.user_lots_type !== 'archive_lots' && isEmpty(obj.year)) {
            delete obj.year
        }

        if (obj.user_lots_type !== 'archive_lots' && isEmpty(obj.month)) {
            delete obj.month
        }

        if (obj.search.length === 0) {
            delete obj.search
        }

        if (obj.type === 'for_sales' && obj.user_lots_type !== 'user_lots') {
            delete obj.type
        }

        if (obj.user_lots_type === 'archive_lots') {
            delete obj.type
        }

        // if (obj.direction === 'desc' && obj.user_lots_type !== 'auction_lots') {
        //     delete obj.direction
        // }

        if (obj.page === 1 || obj.page === 0) {
            delete obj.page
        }


        if (obj.user_lots_type === 'auction_lots' || obj.user_lots_type == 'archive_lots') {
            const {filterInput} = getState().filterSidebar

            const filters = filter(filterInput, {'initial': false})

            filters.map(({name, value}) => {
                if (name === 'filter_category' && auction_category_id === 0) {
                    obj[name] = value.split(',').join('-');
                } else if (name === 'filter_subcategories' && auction_category_id !== 0) {
                    obj[name] = value;
                } else if (name === 'prefix_id') {
                    const {category_id} = find(prefixes_in_categories, {prefix_id: parseInt(value)})
                    obj[name] = value;
                    obj.auction_category_id = category_id;
                } else if (!name.includes(['filter_category', 'filter_subcategories'])) {
                    obj[name] = value;
                }
                return true
            })
        }

        if (obj.auction_category_id === 0) {
            delete obj.auction_category_id
        }

        if (includes(['auction_lots', 'archive_lots'], obj.user_lots_type)) {
            delete obj.auction_category_id
            delete obj.user_lots_type
            delete obj.year
        }

        return obj;
    }
}

export function setLinkData(data) {
    return (dispatch) => {
        const link = queryString.stringify(dispatch(getLinkData(data)));
        dispatch(push(`?${link}`));
    }
}

export function onFilterTabNavChange(filterTabNav) {
    return dispatch => {
        localStorage.setItem('filterTab', filterTabNav);
        dispatch({type: UPDATE_LOTS_DATA, payload: {filterTabNav}})
    }
}

export function setChangeLot(auction_id) {
    return (dispatch, getState) => {
        const lotsState = getState().lots;
        const newArr = [...lotsState.changeLots, ...filter(lotsState.lotsData, {'auction_id': auction_id})];
        dispatch({type: SET_CHANGE_LOT, payload: newArr})
    }
}

export function deleteChangeLot(auction_id) {
    return (dispatch, getState) => {
        const lotsState = getState().lots;
        const el = findIndex(lotsState.changeLots, {'auction_id': auction_id});
        const newArr = [...lotsState.changeLots.slice(0, el), ...lotsState.changeLots.slice(el + 1)];
        dispatch({type: DELETE_CHANGE_LOT, payload: newArr})
    }
}

export function changeAllLots(value) {
    return dispatch => {
        dispatch({type: CHANGE_ALL_LOTS, payload: value})
    }
}

export function onToggleAllLots(value) {
    return (dispatch, getState) => {

        let newArr = [];

        if (value) {
            const lotsState = getState().lots;
            newArr = lotsState.lotsData;
        }
        dispatch({type: ON_TOGGLE_ALL_LOTS, payload: newArr});
    }
}

export function clearChangeLot() {
    return dispatch => {
        dispatch({type: UPDATE_LOTS_DATA, payload: {changeLots: []}})
    }
}

// �������� url (url Creation)
// ���� ���� ������� � ���� ��� ����� ����������� ��� ���������
export function getUrl() {
    return (dispatch, getState) => {
        const obj = dispatch(getParams(true));
        const {user_lots_type, type} = obj;

        let action;
        let controller = 'auction-history/';
        let link = '';

        if (user_lots_type === 'user_lots' && includes(['sale', 'for_sales'], type)) {

            const {user_owner_data} = getState().lots

            link = `/auction/${Helpers.getUserVariableReference(user_owner_data)}/`

            if (type === 'sale') {
                link = `/auction/${Helpers.getUserVariableReference(user_owner_data)}/sold/`
            }

            return {link, obj};
        }

        if (user_lots_type === 'archive_lots') {
            const data = dispatch(getArchiveSwichData(obj.year));
            const params = find(data, {type});
            return {link: params.link, obj, params};
        }

        switch (user_lots_type) {
            case 'purchases':
                action = 'purchased-user-lot';
                break;
            case 'user_bidding':
                action = 'user-bidding';
                break;
            case 'user_lots':
                action = 'user-lots';
                break;
            case 'archive_lots':
                action = 'archive-lots';
                break;
            case 'auction_lots':
            default:
                controller = '';
                action = '';
                break;
        }

        const params = queryString.stringify(obj);
        link = `/${controller}${action}?${params}`;

        return {link, obj};
    }
}

export function getAllParentsByCategoryId(category_id, result = []) {
    return (dispatch, getState) => {
        const {all_categories} = getState().lots

        all_categories.map((item) => {
            if (item.auction_category_id === category_id) {
                result.push(item);

                if (item.parent_category_id !== 0) {
                    dispatch(getAllParentsByCategoryId(item.parent_category_id, result));
                }
            }
            return true
        });
        return result;
    }
}


export function _setSideBarParams(data) {
    return (dispatch) => {
        dispatch(setOnlineModerator(data.onlineStaff));
        dispatch(setOnlineUsers(data.onlineUsers));
        dispatch(setDataStats(data.auctionStats));
    }
}

export function setAllCategories(all_categories) {
    return dispatch => {
        dispatch({type: SET_ALL_CATEGORIES, payload: all_categories})
    }
}


export function setAuctionLinks() {
    return dispatch => {
        // const {user_lots_type} = dispatch(getQueryLinkParams())

        const setSimulateLoading = (val = false) => {
            dispatch({type: UPDATE_LOTS_DATA, payload: {simulateLoading: val}});
        }

        const setFunctionMainLink = ({name, selector}) => {
            if (!isNull(selector)) {
                selector.onclick = (e) => {
                    e.preventDefault();

                    if (name === 'navLinkNewLots') {
                        dispatch(installFilters({name: 'filter_new_lots', value: 1}));
                    }

                    setSimulateLoading(true);
                    setTimeout(setSimulateLoading, 1000)

                    if (name === 'linkYourBids') {

                        const obj = {
                            order: 'title',
                            direction: 'asc',
                            type: 'for_sales',
                            user_lots_type: 'user_bidding'
                        }
                        const data = queryString.stringify(obj);
                        dispatch(push(`/auction-history/user-bidding?${data}`));

                        return true
                    }

                    dispatch(push(`/`));
                }
            }

            return true;
        }

        const linksData = [
            {
                name: 'linkLogo',
                selector: document.querySelector('div#logo div a')
            },
            {
                name: 'linkTab',
                selector: document.querySelector('#navigation li.navTab.auctions.selected a.navLink')
            },
            {
                name: 'navLinkNewLots',
                selector: document.querySelector('#navigation ul.secondaryContent.blockLinksList li a.ak_au_new_lots')
            },
            {
                name: 'linkYourBids',
                selector: document.querySelector('#navigation ul.secondaryContent.blockLinksList li a.ak_au_your_bids')
            },
        ];

        linksData.map((item) => setFunctionMainLink(item));
    }
}

export function setCountersConversationsAndAlerts() {
    return (dispatch, getState) => {

        const {visitor} = getState().lots
        const {conversations_unread, alerts_unread} = visitor

        if (window.XenForo !== undefined && visitor.user_id !== 0) {
            window.XenForo.setCountersConversationsAndAlerts({conversations_unread, alerts_unread})
        }
    }
}

export function getFormattedDate(timestamp, strict = true) {
    return (dispatch, getState) => {
        const {visitor, languages} = getState().lots;
        const {language_id, timezone} = visitor;

        let language = find(languages, {language_id});
        const language_code = (isUndefined(language)) ? 'uk-UA' : language.language_code;

        moment.tz.setDefault(timezone);
        moment.locale(language_code);

        const now = moment();
        const postTime = moment.unix(timestamp);
        const diffMinutes = now.diff(postTime, 'minutes');
        const formatString = 'DD MMMM YYYY HH:mm';

        if (strict) {
            return postTime.format(formatString);
        }

        if (diffMinutes < 1) {
            return moment.duration(now.diff(postTime)).humanize();
        } else if (diffMinutes < 10) {
            return moment.duration(diffMinutes, 'minutes').humanize();
        } else {
            return postTime.format(formatString);
        }
    };
}

export function sendAnswer(auction_id, message) {
    return async (dispatch, getState) => {

        const {setAnswer} = new ProcessApiService();

        let obj = {id: auction_id, comment: message, _xfConfirm: 1}

        setAnswer(obj).then((json) => {
            const {_redirectStatus, _redirectTarget} = json

            if (_redirectStatus === 'ok') {
                console.log(_redirectStatus)
                window.location.assign(_redirectTarget);
            }
        });
    }
}
