import React from 'react';
import SelectComponent from "../../repeating-components/select-component";

const SortingSettingsContentSelect = (props) => {
    return (
        <div className="col-12 col-md-6 mb-1">
            <SelectComponent {...props}/>
        </div>
    );
}
export default SortingSettingsContentSelect