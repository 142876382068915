import React from "react"

import './lots.css';
import NavBarSortMenu from "../nav-bar-sort-menu";
import StartSpinerBlock from "../start-spiner-block";
import TopBar from "../top-bar";
import Sidebar from "../sidebars/sidebars";
import YearsNavPanel from "../years-nav-panel/years-nav-panel";
import ModalImageGallery from "../modals/modal-image-gallery";
import ModalSortingSettings from "../modals/modal-sorting-settings";
import ModalPrefix from "../modals/modal-prefix";
import ModalPromotionLots from "../modals/modal-promotion-lots";
import ModalCategorySettings from "../modals/modal-category-settings/modal-category-settings";
import ModalInlineModCheck from "../modals/modal-inline-mod-check";
import PageNav from "../page-nav";
import AuctionLotsListing from "../auction-lots-listing";
import useLotsFn from "./useLotsFn"
import ShopSortMenu from "../shop-top-bar/shop-sort-menu";
import {useMediaQuery} from "react-responsive";


const Lots = () => {
   const {startLoading, simulateLoading, modalShow, user_lots_type} = useLotsFn()

    const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

    if (startLoading || simulateLoading) {
        return <StartSpinerBlock/>
    }

    return (
        <>
            <div className={`col-md-12 col-lg-3 col-xl-2 pr-lg-1 pl-lg-0 ${isMobile ? 'p-0' : ''}`}>
                <Sidebar/>
            </div>
            <div className={`col-md-12 ml-sm-12 col-lg-9 col-xl-10 pl-lg-1 pr-lg-0 ${isMobile ? 'p-0' : ''}`}>
                <TopBar/>
                <div className='section-main'>
                    <NavBarSortMenu/>
                    <div className="shop-area pt-45">
                        <div className="container-fluid">
                            {user_lots_type === 'archive_lots' && <YearsNavPanel/>}
                            <div className="row flex-row-reverse">
                                <ModalImageGallery/>
                                <ModalSortingSettings/>
                                <ModalPrefix/>
                                <ModalPromotionLots/>
                                <ModalCategorySettings/>
                                {modalShow ? <ModalInlineModCheck/> : null}
                                <ShopSortMenu/>
                                <PageNav>
                                    <AuctionLotsListing/>
                                </PageNav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Lots